//Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    styled,
} from '@mui/material';
import SignUpRiderSuccessController, { configJSON } from "./SignUpRiderSuccessController.web";
import { mainLogo, success } from "./assets";
//Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class SignUpRiderSuccess extends SignUpRiderSuccessController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <MainBoxContainer>
                  <ImageBox>
                    <MainLogo src={mainLogo.default} alt="Success" />
                </ImageBox>
            <BoxMain>
                <StyledImage src={success.default} alt="Success" />
                <TypographyAllSet>{configJSON.allSet}</TypographyAllSet>
                <TypographyGreatNews>{configJSON.greatNews}</TypographyGreatNews>
                <ButtonButton data-testId={"expolreId"} onClick={this.handleRedirect}>
                    <ButtonTypo>{configJSON.exploreride}</ButtonTypo>
                </ButtonButton>
            </BoxMain>
            </MainBoxContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const BoxMain = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#ECFDF5',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        height:"100%",
        justifyContent: 'center',
    },
}));

const MainBoxContainer = styled(Box)(({ theme }) => ({
    width: '100vw',

}));
const ImageBox = styled('div')({
    width: "100%",
    padding:0,
    margin:0,
    display:"flex"
})

const MainLogo = styled('img')(({ theme }) => ({
    width: '161px',
    height: '56px',
    marginLeft: "50px",
    [theme.breakpoints.down('sm')]: {
        marginLeft: "10px",
    },
   
}));

const StyledImage = styled('img')(({ theme }) => ({
    maxHeight: '602px',
    width: "100%",
    maxWidth: "581px",
    [theme.breakpoints.down('sm')]: {
        width:"80%",
        height:"50%"
    },
}));

const TypographyAllSet = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontFamily: "'Inter', Sans-serif",
    fontSize: '48px',
    color: '#87C122',
    margin: "2px",
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        fontSize: '30px',
        margin: "10px",
    }
}));

const TypographyGreatNews = styled(Typography)(({ theme }) => ({
    width: "100%",
    maxWidth: '490px',
    fontWeight: 400,
    fontFamily: "'Inter', Sans-serif",
    fontSize: '30px',
    margin: "2px",
    color: '#475569',
    [theme.breakpoints.down('sm')]: {
        width: '90%',
        fontSize: '18px',
        margin: "10px",
    }
}));

const ButtonButton = styled(Box)({
    width:"100%",
    maxWidth: '440px',
    height: '67px',
    marginTop: '10px',
    marginBottom:"20px",
    color: '#ffffff',
    backgroundColor: '#1D6FB8',
    cursor: "pointer",
    borderRadius: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: "#1D6FB8",
    },
    "@media (max-width: 960px)": {
        width: '70%',
        height: '40px',
    }
});
const ButtonTypo = styled(Typography)({
    fontWeight:400,
    fontFamily:"'Inter', Sans-serif",
    fontSize:"22px",
    "@media (max-width: 960px)": {
        fontSize:"18px",
    }
})

// Customizable Area End
