import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  TextField,
  MenuItem,
  Select
} from "@mui/material";
import Button from '@mui/material/Button';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web"


// Customizable Area End

import AddPaymentController, {
  Props,
  configJSON,
} from "./AddPaymentController";

import {  backArrow, cvcImg, editImg, visaImg } from "./assets";





export default class AddPayment extends AddPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  addUpdatedPaymentMethod = () => {
    return (<BoxWrapper>
      <BoxInner>
        <Box style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }}
        data-testId = 'backToSettingId'
        onClick={()=> this.handleNavigation("SideMenuBasic")}>
          <img src={backArrow.default}  data-testId = 'backbtnId' onClick={() => this.setState({ pagetoshow: "" })} />
          <Typography style={webstyle.backText}>{configJSON.back}</Typography>
        </Box>
        <Typography style={webstyle.cardText}>Card Details</Typography>
      </BoxInner>
      <BoxDesign>
        <BoxField>
          <Typography style={webstyle.labelText}>Cardholder name</Typography>
          <TextField
            value="Cameron Rain"
            name='addr'
            style={webstyle.formTextField}
            InputProps={{
              endAdornment: (
                <img src={editImg.default} style={{ width: "20px", height: "20px" }} alt="edit" />
              )
            }}
          />
        </BoxField>
        <BoxField>
          <Typography style={webstyle.labelText}>Country or region</Typography>
          <Select
           data-testId = 'stateId'
            value={this.state.selectedState}
            onChange={(event) => this.setState({ selectedState: event?.target?.value })}
            style={webstyle.formTextField}
            sx={{
              "& .MuiSelect-icon": {
                color: "#0F172A",
              },
            }}
          >
            <MenuItem key=' ' value=' '>United States</MenuItem>
            <MenuItem key='1' value='1'>America</MenuItem>
            <MenuItem key='2' value='2'>London</MenuItem>
          </Select>
        </BoxField>

        <BoxField>
          <Typography style={webstyle.labelText}>Home Address</Typography>
          <TextField
            value="906 Hummingbird Dr, San Jose, California, 95125"
            name='addr'
            style={webstyle.formTextField}
            InputProps={{
              endAdornment: (
                <img src={editImg.default} style={{ width: "20px", height: "20px" }} alt="edit" />
              )
            }}
          />
        </BoxField>

        <BoxField>
          <Typography style={webstyle.labelText}>Credit card number</Typography>
          <TextField
            type="text"
            placeholder="Card number"
            style={webstyle.formTextField}
            value={this.state.cardnumber}
            data-test-id='cardcheckid'
            onFocus={() => this.setState({ isCardinputfocused: true })}
            onBlur={() => this.setState({ isCardinputfocused: false })}
            onChange={this.handleCardNumberChange}
            InputProps={{
              endAdornment: (
                <img src={visaImg.default} style={{ width: "37px", height: "29px" }} alt="visa" />
              )
            }}
          />
        </BoxField>

        <ExpiryWrap>
          <Box>
            <Typography style={webstyle.labelText}>Credit card number</Typography>
            <TextField
              type="text"
              placeholder="Expiry date"
              style={{ ...webstyle.formTextField, maxWidth: "210px" }}
              value={this.state.expirrydate}
              name="expirrydate"
              onChange={this.handleChange}
              data-test-id="handleexpirydateid"
            />
          </Box>
          <Box>
            <Typography style={webstyle.labelText}>CVC</Typography>
            <TextField
              value="123"
              name='cvc'
              style={{ ...webstyle.formTextField, maxWidth: "210px" }}
              InputProps={{
                endAdornment: (
                  <img src={cvcImg.default} style={{ width: "24px", height: "24px" }} alt="cvc" />
                )
              }}
            />
          </Box>
        </ExpiryWrap>
        <Button style={webstyle.saveBtn}>Save</Button>
        <Button style={{...webstyle.cancelBtn, marginBottom:"20px", cursor: "pointer" }} data-testId ="bckbtnId" onClick={()=> this.handleNavigation("SideMenuBasic")} >Back</Button>

      </BoxDesign>
    </BoxWrapper>)
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BoxMain>
        <NavigationMenu navigation={this.props.navigation} id={""} index={-1} imageurl={""}/>
        {this.addUpdatedPaymentMethod()}
      </BoxMain>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const BoxMain = styled(Box)({
  minHeight: "100vh",
  width: "100%",
  backgroundColor: "#ECFDF5",
  "@media (max-width: 960px)": {
    minHeight: "auto",
  },
});

const ExpiryWrap = styled(Box)({
    display: "flex",
    gap: "20px",
    alignItems: "center",
    "@media (max-width: 960px)": {
     justifyContent:"center"
  },
})

const BoxWrapper = styled(Box)({
    display: "flex",
    gap: "17%",
    paddingLeft: "40px",
    paddingTop: "16px" ,
    "@media (max-width: 960px)": {
        flexDirection: "column",
        gap:"5%",
        width:"100%",
        paddingLeft: "0px",
        alignItems:"center"
      },
})
const BoxInner = styled(Box)({
  "@media (max-width: 960px)": {
    flexDirection: "column",
    width:"100%",
    paddingLeft: "25px",
  },
})
const BoxDesign = styled(Box)({
    width: "100%",
    maxWidth: "440px",
    display: "flex",
    paddingTop: "25px",
    flexDirection: "column",
    gap: "20px",
    "@media (max-width: 960px)": {
        alignItems: "center",
        width: "90%",
      },
})

const BoxField = styled(Box)({
    width:"100%"
})

const webstyle = {

  labelText: {
      fontFamily: "Inter",
      paddingBottom: "4px",
      lineHeight: "22px",
    fontWeight: 700,
    color: "#334155",
    fontSize: "14px"
  },

  formTextField: {
      maxWidth: "440px",
      width: '100%',
      borderRadius: "8px",
      height: "56px",
    border: "1px solid #CBD5E1",
    fontFamily: "Inter",
    background: "white",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: "#000000"
  },
  saveBtn: {
      height: "56px",
      maxWidth: "440px",
    width:"100%",
    borderRadius: "8px",
    backgroundColor: "#1D6FB8",
    fontWeight: 400,
    fontFamily: "Inter",
    lineHeight: "26px",
    fontSize: "18px",
    cursor: "pointer",
    textTransform: "capitalize" as "capitalize",
    color: "#FFFFFF",
  },
  cancelBtn: {
      width:"100%",
      maxWidth: "440px",
      borderRadius: "8px",
      height: "56px",
    color: "#1D6FB8",
    fontWeight: 400,
    fontFamily: "Inter",
    fontSize: "18px",
    backgroundColor: "white",
    lineHeight: "26px",
    textTransform: "capitalize" as "capitalize",
    border: "1px solid #1D6FB8 ",
  },
  backText: {
      fontWeight: 400,
      fontFamily: "Inter",
      lineHeight: "22px",
      fontSize: "14px",
    color: "#334155"
  },
  cardText: {
      fontWeight: 700,
      fontFamily: "Inter",
      lineHeight: "40px",
      fontSize: "30px",
    color: "#334155"
  }
}

// Customizable Area End
