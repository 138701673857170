// Customizable Area Start
import React from "react";
import { configJSON, Props } from "./HelpCentreController";
import HelpCentreController from "./HelpCentreController";
import { Box, Typography, styled } from "@mui/material"
import { contact, question, tutorial } from "./assets";
import HelpCentreFaq from "./HelpCentreFaq.web";
// Customizable Area End

// Customizable Area Start

const Helpboxcontainer = styled(Typography)({
  paddingBottom: "20px"
});

const Helpcenteroptionboxcontainer = styled(Box)({
  width: "70%",
  display: "flex",
  gap: "24px",
  alignItems: "center",
  "@media (max-width: 1150px)": {
    width: "80%",
  },
  "@media (max-width: 900px)": {
    width: "100%",
  },
  "@media (max-width: 600px)": {
    flexDirection: "column"
  },
});

const ContactelpcenterOptionbox = styled(Box)({
  width: "200px",
  height: "200px",
  border: "1px solid #E2E8F0",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  background: "#CBD5E1",
  gap: "7px",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

const FaqhelpcenterOptionbox = styled(Box)({
  width: "200px",
  height: "200px",
  border: "1px solid #E2E8F0",
  alignItems: "center",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column" as "column",
  justifyContent: "center",
  gap: "7px",
  background: "#F59E0B",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

const TutorialshelpcenterOptionbox = styled(Box)({
  width: "200px",
  height: "200px",
  border: "1px solid #E2E8F0",
  alignItems: "center",
  borderRadius: "16px",
  display: "flex",
  gap: "7px",
  flexDirection: "column" as "column",
  justifyContent: "center",
  background: "#BFDBFE",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});


// Customizable Area End

export default class HelpCentre extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  helpCenterOptions = () => {
    return (
      <Helpboxcontainer >
        <Typography style={{
          fontFamily: "Inter",
          fontWeight: 700,
          fontSize: "30px",
          lineHeight: "40px",
          letterSpacing: "-0.5%",
          paddingBottom: "23px"
          }}>How can we help you?</Typography>
        <Helpcenteroptionboxcontainer>
          <ContactelpcenterOptionbox onClick={() => this.handleNavigation("Contactus")} data-test-id="contactredirectid">
            <img src={contact.default} />
            <Typography style={webstyle.optionstext}>{configJSON.contactText}</Typography>
          </ContactelpcenterOptionbox>
          <FaqhelpcenterOptionbox onClick={() => this.handleNavigation("Faq")} data-test-id="getqueansid">
            <img src={question.default} />
            <Typography style={webstyle.optionstext}>{configJSON.faqText}</Typography>
          </FaqhelpcenterOptionbox>
          <TutorialshelpcenterOptionbox onClick={()=> this.handleRouteNavigation('TutorialPage')} data-test-id = "opentutorialspage"> 
            <img src={tutorial.default} />
            <Typography style={webstyle.optionstext}>{configJSON.tutorials}</Typography>
          </TutorialshelpcenterOptionbox>
        </Helpcenteroptionboxcontainer>
      </Helpboxcontainer>
    )
  }

  helpCenterpage = () => {
    switch (this.state.showpage) {
      case "helpcenter":
        return this.helpCenterOptions()
      case "helpcenterfaq":
        return <HelpCentreFaq navigation={undefined} id={""} helpcenterQA={this.state.helpCentreQA} />
      default:
        break;
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.helpCenterpage()}
      </>


      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webstyle = {
  helpcenterOptionbox: {
    width: "200px",
    height: "200px",
    border: "1px solid #E2E8F0",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },

  optionstext: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    color: "#0F172A",
    cursor: "pointer"
  },

  helpcenteroptionboxcontainer: {
    width: "70%",
    display: "flex",
    gap: "24px",
    alignItems: "center"
  },

}
// Customizable Area End