// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import { Message } from "../../../framework/src/Message";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { tickSign } from "./assets";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface ApiCallFunction {
    contentType: string,
    method: string,
    endPoint: string,
    body?: {}
}
export interface Additional {
    id:number,
    name:string,
    img:{ default: string },
    isSelected:boolean,
    tickSign:{ default: string };
}
interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
  }
  interface Vehicle {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      base_rate: string;
      per_mile_rate: string;
      image: string;
    };
    isSelected: boolean;
    tickSign: string;
  }
  interface Vehicle2 {
      id: number;
      name: string;
      base_rate: string;
      per_mile_rate: string;
      image: string;
    isSelected: boolean;
    tickSign: string;
  }
  interface CountryInterface {
   label:string,
   value:string
  } 
  
  interface Geometry {
    location: {
      lat: number;
      lng: number;
    };
  }
  export interface AutocompleteType {
    getPlace: () => google.maps.places.PlaceResult;
    addListener: (event: string, callback: Function) => void;
    setBounds: (bounds: google.maps.LatLngBounds) => void;
  }
  
  
  interface Place {
    address_components: AddressComponent[];
    formatted_address: string;
    geometry: Geometry;
    place_id: string;
    html_attributions: string[];
    utc_offset: () => void;
  }

export interface ValidResponse {
    data: {
        id: string,
        type: string,
        attributes: {
            email: string;
            first_name: string;
            last_name: string;
            role_id: number;
            status: string
        }
    },
    meta: {
        token: string;
    }
}

export interface InValidResponse {
    errors: [
        {
            detail: string
        }
    ]
}

export interface InValidUpdateResponse {
    errors: [string]
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}
export interface S {
    // Customizable Area Start
    role: string;
    firstName: string;
    lastName: string;
    email: string;
    emailError: boolean;
    password: string;
    agreeToTerms: boolean;
    openErrorModal: boolean;
    currentStep: number;
    openSelectModal: boolean;
    anythingElse: string;
    passwordCriteria: {
        hasUpperCase: boolean,
        hasLowerCase: boolean,
        hasNumber: boolean,
        hasSpecialChar: boolean,
        isValidLength: boolean
    };
    showPassword: boolean;
    mobileNumber: string;
    homeAddress: string;
    homeAddressError: boolean;
    addressOne: string;
    vehicalId:number | null
    addressOneError: boolean;
    addressTwo: string;
    fieldClicked: boolean;
    addressTwoError: boolean;
    countryCode:string;
    additionalData:string;
    city: string;
    cityError: boolean;
    changeBorder:boolean;
    stateAdd: string;
    autoCompleteStart:AutocompleteType | null
    state:string;
    stateAddError: boolean;
    country: CountryInterface;
    countryError: boolean;
    zipCode: string;
    zipCodeError: boolean;
    showApiError: boolean;
    apiErrorMessage: string;
    btnLoader: boolean;
    requirement: string;
    allinputdata: object;
    additionalField:Vehicle[] | null;
    riderMobNoError:boolean;
    mobileNumberError:boolean;
    mobileNumberError2:boolean;

    // Customizable Area End
}
export interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
export default class SignUpDriverController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createAccountForRiderApiCallId: string = "";
    updateRiderAccountApiCallId: string = "";
    getGoogleMapApiCallId: string = "";
    riderMobileNumberApiCallId:string="";
    riderVehicalApiCallId:string="";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
      
        this.receive = this.receive.bind(this);
        // Customizable Area End

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            role: "rider",
            firstName: "",
            lastName: "",
            countryCode:"44",
            state:"",
            email: "",
            changeBorder:false,
            vehicalId:null,
            additionalField:null,
            emailError: false,
            password: "",
            additionalData:"",
            currentStep: 1,
            fieldClicked:false,
            openSelectModal: false,
            openErrorModal: false,
            anythingElse: '',
            autoCompleteStart:null,
            agreeToTerms: false,
            showPassword: false,
            passwordCriteria: {
                hasUpperCase: false,
                hasLowerCase: false,
                hasNumber: false,
                hasSpecialChar: false,
                isValidLength: false
            },
            mobileNumber: "",
            homeAddress: "",
            homeAddressError: false,
            addressOne: "",
            addressOneError: false,
            addressTwo: "",
            addressTwoError: false,
            city: "",
            cityError: false,
            stateAdd: "",
            stateAddError: false,
            country: {value: 'GB', label: 'United Kingdom'},
            countryError: false,
            zipCode: "",
            zipCodeError: false,
            showApiError: false,
            apiErrorMessage: "",
            btnLoader: false,
            requirement: "",
            allinputdata: {},
            riderMobNoError:false,
            mobileNumberError:false,
            mobileNumberError2:false

            // Customizable Area End
        };
    }

    // Customizable Area Start
    async componentDidMount() {
        // Customizable Area Start
        this.getVehicalData()
        this.setState({currentStep:Number(localStorage.getItem("navigationStep")) || 1})
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const isTermsCondsAccepted = message.getData(
                getName(MessageEnum.TermAndConditionCheck)
            );
            const Prefilledinputdata = message.getData(
                getName(MessageEnum.Sendingbackallinputsdata)
            );
            this.setState({
                agreeToTerms: isTermsCondsAccepted, firstName: Prefilledinputdata.first_name, lastName: Prefilledinputdata.last_name,
                email: Prefilledinputdata.email, password: Prefilledinputdata.password, role: Prefilledinputdata.role_id,
                emailError: Prefilledinputdata.emailError, passwordCriteria: Prefilledinputdata.passwordCriteria
            })

        }
        this.receiveVehicalDetials(message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId1 = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson1 = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId1 && responseJson1) {
                this.callBackResponse(apiRequestCallId1, responseJson1);
            }
            if (apiRequestCallId1 === this.riderMobileNumberApiCallId) {
                if (responseJson1.errors?.[0]==='Mobile number has already been taken') {
                this.setState({riderMobNoError:true})
                }
                if (responseJson1.hasOwnProperty('mobile_number')&& responseJson1.mobile_number !== null) {
                  this.setState({riderMobNoError:false})
                  this.setState((prevState) => ({
                    currentStep: prevState.currentStep + 1,
                    btnLoader: false,
                    showApiError: false,
                    apiErrorMessage: ""
                }));
                }
              }
        }
        // Customizable Area End
    }

    apiCallFunction = async (data: ApiCallFunction) => {
        const { contentType, method, endPoint, body } = data;
        let token = await getStorageData("tempToken");

        const header = {
            'Content-Type': contentType,
            token,
        }

        const requestMessage1 = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        requestMessage1.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage1.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        requestMessage1.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        )
        body &&
            requestMessage1.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )

        runEngine.sendMessage(requestMessage1.id, requestMessage1);
        return requestMessage1.messageId;
    };

    callBackResponse = (apiRequestCallID: string, responseJSON: ValidResponse & InValidResponse & InValidUpdateResponse) => {
        if (this.isValidResponse(responseJSON)) {
            this.apiSuccessCallBacks(apiRequestCallID, responseJSON)
        };

        if (this.inValidResponse(responseJSON)) {
            this.apiErrorCallBacks(apiRequestCallID, responseJSON)
        };
    };

    isValidResponse = (responseJson: ValidResponse) => {
        return responseJson && responseJson.data;
    };

    inValidResponse = (errorReponse: InValidResponse) => {
        return errorReponse && errorReponse.errors;
    };

    apiSuccessCallBacks = (apiRequestCallID: string, responseJSON: ValidResponse) => {
        if (apiRequestCallID == this.createAccountForRiderApiCallId) {
            this.handleSuccessCreateAccount(responseJSON.data.id, responseJSON.meta.token)
        } else if (apiRequestCallID == this.updateRiderAccountApiCallId) {
            this.handleSuccessUpdateAccount();
        } else if (apiRequestCallID == this.getGoogleMapApiCallId) {

        }
    };

    apiErrorCallBacks = (apiRequestCallID: string, responseJSON: InValidResponse & InValidUpdateResponse) => {
        if (apiRequestCallID == this.createAccountForRiderApiCallId) {
            this.handleFailureCreateAccount(responseJSON.errors[0].detail)
        } else if (apiRequestCallID == this.updateRiderAccountApiCallId) {
            this.handleFailureUpdateAccount(responseJSON.errors[0])
        }
    };

    handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpDriver");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

    handleChangeForFLName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;

        const sanitizedValue = value.replace(/[^a-zA-Z]/g, '');

        this.setState(prevState => ({
            ...prevState,
            [name]: sanitizedValue,
        }));
    }
    formatNumber = (number: string): string => {
        let cleaned = number.replace(/\D/g, "").slice(0, 12); 
      
        if (cleaned.length <= 3) {
          return cleaned;
        } 
        if (cleaned.length <= 7) {
          return cleaned.replace(/(\d{3})(\d{1,4})/, "$1-$2"); 
        }
        if (cleaned.length <= 10) { 
          return cleaned.replace(/(\d{3})(\d{4})(\d{1,3})/, "$1-$2-$3"); 
        }
        if (cleaned.length === 11) {
          return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"); 
        }
        return cleaned.replace(/(\d{3})(\d{4})(\d{5})/, "$1-$2-$3"); 
      };
    

    handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.currentTarget;
        const { name, value } = target;
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
            [`${name}Error`]: false
        }));
    };
    
    handleChangeMobileNo = (event: any) => {
        
        const { name, value } = event.currentTarget;
        const isValid = /^\d{3}-\d{4}-\d{3,5}$/.test(value);
        this.setState(prevState => ({
          ...prevState,
          [name]: value,
          [`${name}Error`]: !isValid, 
        }));
      };
     
      countryCodeData = (value:string) => {
        this.setState({countryCode:value})
      };
      getVehicalData = async () => {
        let token = await getStorageData("token");

        const header = {
            'Content-Type': configJSON.contentTypeApiAddDetail,
            token,
        }
        const body=null

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.riderVehicalApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getVihicalaApiEndPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        )
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };
    receiveVehicalDetials = async(message: Message) => {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.riderVehicalApiCallId &&
			this.riderVehicalApiCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson){
                const formattedData = responseJson.data.map((item: Vehicle) => ({
                    ...item,  
                    isSelected: false,
                    tickSign: tickSign.default
                  }));
                  this.setState({additionalField:formattedData})
                
		}

    }
	}

      handleFieldClick = (id:number,name:string) => {
        this.setState({additionalData:name,fieldClicked:!this.state.fieldClicked,vehicalId:id})
        this.setState((prevState) => {
          const updatedFields = prevState.additionalField && prevState.additionalField.map((data) => {
            if (data.id === id.toString()) {
                data.isSelected = true;
              } else {
                data.isSelected = false;
              }
              return data;
          });
          
          return { additionalField: updatedFields };
        });

      };
    handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        const emailError = !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);
        this.setState({
            email,
            emailError,
        });
    };

    handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const password = event.target.value;

        let passwordCriteria = {
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasNumber: /\d/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            isValidLength: password.length >= 8,
        };

        this.setState({
            password,
            passwordCriteria,
        });
    };

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        });
    };

    navigateTermscondition = (route: string) => {
        localStorage.removeItem("redirectfrom")
        setStorageData("role", "rider");
        const navigate: Message = new Message(getName(MessageEnum.NavigationTermsandContion));
        navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        navigate.addData(getName(MessageEnum.NavigationcheckFromtermcondtion), true);
        navigate.addData(getName(MessageEnum.AllInputsdataforsignup), {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            emailError: this.state.emailError,
            passwordCriteria: this.state.passwordCriteria,
            role_id: this.state.role
        });


        this.send(navigate);
    }

    handleCheckboxChange = () => {
        this.setState({
            agreeToTerms: !this.state.agreeToTerms,
        });
    };

    handleSelectRequirement = (requirement: string) => {
        this.setState({ requirement, openSelectModal: false })
    }

    handleSubmitRiderAccount = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ btnLoader: true });
        let body = {
            data: {
                type: "email_account",
                attributes: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    password: this.state.password,
                    role_id: "1"
                }
            }
        }

        this.createAccountForRiderApiCallId = await this.apiCallFunction({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.Post,
            endPoint: configJSON.postAPIDriver,
            body: body
        });

    };

    handleSuccessCreateAccount = async (id: string, token: string) => {

        setStorageData("userId", id);
        setStorageData("token", token);
        setStorageData("role_id", "rider");
        this.setState((prevState) => ({
            currentStep: prevState.currentStep + 1,
            btnLoader: false,
            showApiError: false,
            apiErrorMessage: "",
        }));
    };

    handleFailureCreateAccount = (message: string) => {
        this.setState({ showApiError: true, apiErrorMessage: message, btnLoader: false });
    };

    handleSubmitTwo = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!this.state.mobileNumber){
            this.setState({mobileNumberError2:true})
        }
        else{
            this.checkMobileNumber2()
        }
    };
    
    onFieldClicked=()=>{
        this.setState({fieldClicked:!this.state.fieldClicked})
        if(!this.state.additionalField){
        this.getVehicalData()}
    }
    userAddress=(event: React.ChangeEvent<HTMLInputElement>)=>{
        
        this.setState({homeAddress:event.target.value})
    }
    getParsedId2=()=> {
        let id:any = localStorage.getItem('userId');
        
        if (id && id.startsWith('"') && id.endsWith('"')) {
          id = parseInt(id.replace(/"/g, ""), 10);
        }
        
        return id;
      }
    checkMobileNumber2 =async () => {
        const id = this.getParsedId2();
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
        const httpBody = {
          "data": {
            "type": "email_account",
           "attributes": {
                "mobile_number": this.state.mobileNumber.replace(/-/g, ""),
                "country_code":this.state.countryCode,
                "vehicle_id":this.state.vehicalId,
                "anything_else_you_did_like_us_to_know": this.state.anythingElse
    }
          }
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.riderMobileNumberApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
           `${configJSON.mobileNumberApiEndPoint}${id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.putApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      
      };
    
    takeonlyNumber = (event: any) => {
        const input = event.target as HTMLInputElement;
        input.value = input?.value?.replace(/\D/g, "");
    }
    clearAddressField = ()=>{
        this.setState({homeAddress:"",addressOne:"",city:"",state:"",addressTwo:"",zipCode:"",country:{value:"GB",label:"UnitedKingdom"}})
    }

    goToBackStep = () => {
        this.setState((prevState) => ({
            currentStep: prevState.currentStep - 1,
        }));
    };

    handleOpenSelectModal = () => {
        this.setState({ openSelectModal: true });
    };

    handleCloseSelectModal = () => {
        this.setState({ openSelectModal: false });
    };

    handleFinalStep = async () => {
        const {
            homeAddress,
            addressOne,
            addressTwo,
            city,
            state,
            country,
            zipCode,
        } = this.state;

      

        if (!homeAddress || !addressOne  || !city || !country || !zipCode) {
            this.setState({
                homeAddressError: !homeAddress,
                addressOneError: !addressOne,
                cityError: !city,
                stateAddError: !state,
                countryError: !country,
                zipCodeError: !zipCode,
            });
        } else {
            this.handleSubmitFinalForm();
        }
    }

    handleSubmitFinalForm = async () => {
        const id = await getStorageData("userId");
        this.setState({ btnLoader: true })
        let body = {
            data: {
                type: "email_account",
                attributes: {
                    home_address: this.state.homeAddress,
                    address_line_one: this.state.addressOne,
                    address_line_two: this.state.addressTwo,
                    city: this.state.city,
                    state: this.state.state,
                    zip_code: this.state.zipCode,
                    country: this.state.country.label
                }
              }
        }
        

        this.updateRiderAccountApiCallId = await this.apiCallFunction({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.putMethod,
            endPoint: `${configJSON.updateRiderAccount}?id=${id}`,
            body: body,
        });
    };

    handleSuccessUpdateAccount = async () => {
        this.setState({
            btnLoader: false,
            showApiError: false,
            apiErrorMessage: "",
        });
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpRiderSuccess");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

    handleFailureUpdateAccount = (message: string) => {
        this.setState({ showApiError: true, apiErrorMessage: message, btnLoader: false });
    };

    handleErrorOpen = () => {
        this.setState({ openErrorModal: true });
    };

    handleErrorClose = () => {
        this.setState({ openErrorModal: false });
    };

    handleGetPlace = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let place = event.target.value;
        this.setState({ homeAddress: place, homeAddressError: false })
    };

    handleLogin = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    handleCountryChange = (data:CountryInterface)=>{
        this.setState({country:data})
    }
  processAddressComponents = (components: any) => {
    let addressData = {
      street: "",
      streetNumber: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      countryCode: ""
    };
  
    components.forEach((component: any) => {
      if (component.types.includes("street_number")) {
        addressData.streetNumber = component.long_name;
      }
      if (component.types.includes("route")) {
        addressData.street = component.long_name;
      }
      if (component.types.includes("locality")) {
        addressData.city = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        addressData.state = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        addressData.zip = component.long_name;
      }
      if (component.types.includes("country")) {
        addressData.country = component.long_name;
        addressData.countryCode = component.short_name;
      }
    });
  
    return addressData;
  };
  
  handleSelect = (autocomplete: AutocompleteType | null) => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressData = place.address_components ? this.processAddressComponents(place.address_components) : null;
  if(addressData){

      this.setState({
          homeAddress: place.formatted_address || " ",
          addressOne: `${addressData.streetNumber} ${addressData.street}`.trim(),
          zipCode: addressData.zip,
          state: addressData.state,
          country: { value: addressData.countryCode, label: addressData.country },
          city: addressData.city
        });
    }
    }
  };
  
    getColor=(isSelected:boolean) =>{
        return isSelected ? '#0B0C13' : '#A0A0A0';
      }
      changeBorderColor=()=>{
        this.setState({changeBorder:!this.state.changeBorder})
      }

      componentDidUpdate(prevProps:any, prevState:any) {
        if (prevState.currentStep !== this.state.currentStep) {
            window.scrollTo(0, -50); 
        }
      }
    // Customizable Area End 
}



