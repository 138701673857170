// @ts-nocheck 
import React from "react";

// Customizable Area Start
// @ts-nocheck 
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Typography,
  Button,
  Box,
  styled, Table, TableBody, TableRow, TableCell, IconButton, Dialog,
  DialogContent,
  Checkbox,Popover,Paper,
  FormControlLabel, MenuList,
  MenuItem,ClickAwayListener,DialogActions
} from "@mui/material";
import TripHistoryController, {
  Props
} from "./TripHistoryController.web";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TypeNull,deleteImg,edit, moreIcon } from './assets';
export const configJSON = require("./config");
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// Customizable Area End

// Customizable Area Start
const rides = [
  {
    date: "Oct 5,",
    time: "06:30 AM",
    from: "742 Evergreen Terrace, Springfield, IL 62704 dfgfdfg dfghnbvcdcfvbvcdsxdfvbvcsdfvvcxszxd",
    to: " 1600 Amphitheatre Parkway, Mountain View, CA 94043sdfghgfdxsdcfvgbhnjmhbvcxxdcfvbn cfghndxf fghhg dcfgsxfvd dfghfdfgs dfgfdsdfgfs",
    price: "$25",
    status: "In Progress"
  },
  {
    date: "Oct 5,",
    time: "06:30 AM",
    from: "742 Evergreen Terrace, Springfield, IL 62704",
    to: " 1600 Amphitheatre Parkway, Mountain View, CA 94043",
    price: "$25",
    status: "Scheduled"
  },
];

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: "0 18px", 
  width: "100%",
});


const RowContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "8px",
  padding: "20px",
  borderLeft: "4px solid #87C122", 
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  width: "100%"
});
const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: "30px",
  whiteSpace: "nowrap", 

});
const OrderSummaryText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color:'#0F172A',
  fontWeight: 600,
  marginBottom: '5px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

const TripDetailsText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  marginBottom: '10px',
  color:'#475569',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom:'20px',
  backgroundColor: '#1D6FB8',
  fontSize:'16px',
  width:'30%',
  fontWeight:700,
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  color: 'white',
  textTransform: 'none',
  borderRadius:'10px',
  '&:hover': {
    backgroundColor: '#303f9f',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px', 
    width:'80%',
  },
}));
const StyledImage = styled('img')(({ theme }) => ({
  width: '30%',
  height: 'auto',
  objectFit: 'cover',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    width: '70%', // Adjust for smaller screens
  },
  [theme.breakpoints.down('xs')]: {
    width: '70%', // Further adjust for extra small screens
  },
}));
const StyledContainer = styled('div')(({ theme }) => ({
  overflowX: 'hidden',
  width: '95%',
  margin: '0 auto', // Center the container horizontally
  [theme.breakpoints.down('sm')]: {
    width: '100%', // Full width on smaller screens
  },
}));
const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: "16px", // Apply border radius to the dialog
  },
});
const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "30vw",
  maxWidth: "500px",
  minWidth: "250px",
  textAlign: "center",
  boxSizing: "border-box",
  padding: "20px", 
});

const StyledButton2 = styled(Button)({
  display: "flex",
  alignItems: "center",
  width: "100%", // Full width for better click area
  justifyContent: "flex-start", // Align icon and text to the left
  padding: "10px",
  fontSize: "18px",
  fontWeight: 400,
  paddingLeft:'10%',
  textTransform: "none",
  color:'#0F172A'
});
const ActionsContainer = styled(DialogActions)`
  justify-content: center;
  padding-bottom: 16px;
  padding-Top:20px;
`;
const StyledCancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#E7E5E4",
  color: "#64748B",
  borderRadius: "10px",
  padding: "8px 16px",
  fontSize: "14px",
  fontWeight: "500",
  minWidth: "100px",
  marginRight:'20px',
  textTransform: "none", // Remove uppercase text

  "&:hover": {
    backgroundColor: "#D6D3D1",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "6px 12px",
    minWidth: "80px",
  },
}));

const CardContainer1 = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  borderRadius: "16px",
  boxShadow: theme.shadows[3],
  width: "98%",
  height: "153px",
  borderLeft: "5px solid #87C122",
  margin: "10px 0px",
  backgroundColor: theme.palette.background.paper,
  // [theme.breakpoints.down(1100)]: {
  //     overflowX: "scroll"
  // },
  [theme.breakpoints.down(580)]: {
      padding: "12px"
  },
  overflowY: "auto",
  // [theme.breakpoints.down(1100)]: {
  //     overflowX: "scroll"
  // },
  scrollbarWidth: "thin",
  scrollbarColor: "#4cae4f #eaf7ea",

  "&::-webkit-scrollbar": {
      width: "8px",
  },
  "&::-webkit-scrollbar-track": {
      background: "#eaf7ea",
      borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
      background: "#4cae4f",
      borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
      background: "#3b8e3e",
  },
}));

const InfoBox = styled(Box)({
  flexGrow: 1
});

const InfoBox1 = styled(Box)({
  flexGrow: 1,
  textAlign: "end"
});

const HeaderTextBox = styled(Box)({
  display: "flex",
  height: "64px",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px",
  backgroundColor: "#FFFFFF",
  borderRadius: "8px"
});

const HeaderText = styled("p")({
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: 700,
  fontFamily: "Inter",
});

const TextHeader = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 700,
  fontFamily: "Inter",
  lineHeight: "26px",
  [theme.breakpoints.down(580)]: {
  fontSize: "14px",
  },
}));

const TextBody = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: "Inter",
  lineHeight: "24px",
  marginTop: "10px",
  [theme.breakpoints.down(580)]: {
      fontSize: "12px",
      },
}));

const PriceDistanceBox = styled(Box)({
  textAlign: "right",
});
const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  padding: "15px 30px",
      [theme.breakpoints.down(900)]: {
      flexDirection: "column"
  },
}));

const CardSections = styled(Box)(({ theme }) => ({
  width: "50%",
  // height: "100%",
  [theme.breakpoints.down(900)]: {
      width: "100%"
  },
}));

const DateBox = styled(Box)({
  display: "flex",
  alignItems: "flex-end",
  gap: "10px"
});

// Styled Delete Button
const StyledDeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DC2626",
  color: "#FFFFFF",
  borderRadius: "10px",
  padding: "8px 16px",
  fontSize: "14px",
  fontWeight: "500",
  minWidth: "100px",
  textTransform: "none",

  "&:hover": {
    backgroundColor: "#B91C1C",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "6px 12px",
    minWidth: "80px",
  },
}));
// Customizable Area End

export default class TripHistory extends TripHistoryController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (

      <StyledContainer>
        <DateBox >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="Basic date picker" style={{background: "white"}} />
          </LocalizationProvider>

          <Autocomplete
            disablePortal
            options={[1,2,3,4]}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Select status..." style={{background: "white"}} />}
          />
        </DateBox>

        <Box height={220}>
          <CardContainer1>
            <Box style={{ display: "flex" }}>
              <InfoBox>
                <TextBody style={{ fontWeight: 700, marginTop: "0px" }}>
                  Monday <br /> January 22,<br/> 2024 <br /> 08:00 AM
                </TextBody>
              </InfoBox>

              <InfoBox>
                <TextHeader variant="body1">
                  Start:
                </TextHeader>
                <TextBody>
                  Maple Street, Springfield
                </TextBody>
              </InfoBox>

              <InfoBox>
                <TextHeader variant="body1">
                  Destination:
                </TextHeader>
                <TextBody>
                  2118 Thornridge Cir. Syracuse
                </TextBody>
              </InfoBox>

              <InfoBox>
                <TextHeader variant="body1">
                  Price:
                </TextHeader>
                <TextBody>
                  $12
                </TextBody>
              </InfoBox>

              <InfoBox1 style={{}}>
                <img src={moreIcon.default}/>
              </InfoBox1>
            </Box>
          </CardContainer1>
        </Box>
      </StyledContainer>

    );
    // Customizable Area End
  }


}


// Customizable Area Start
const Buttonbox = styled(Box)({
  width: "100%",
  height: "72px",
  display: "flex",
  paddingLeft: "10px",
  paddingRight: "10px",
  background: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "16px",
  "@media (max-width: 800px)": {
    width: "80%",
  },
  "@media (max-width: 700px)": {
    width: "100%",
  },
  "@media (max-width: 575px)": {
    width: "100%",
    flexDirection: "column",
    height: "150px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
});

const Tabbox = styled(Box)({
  width: "100%",
  display: "flex",
  height: "100px",
  "@media (max-width: 575px)": {
    height: "auto",
    width: "100%"
  },
});


const Singlebuttonbox = styled(Box)({
  width: "45%",
  display: "flex",
  justifyContent: 'center',
  alignItems: "center",
  "@media (max-width: 430px)": {
    width: "100%",
  },
  "@media (max-width: 380px)": {
    width: "100%",
  },
});




const webstyle = {
  dateBox: {
    display: "flex",
    alignItems: "felx-end",
    gap: "10px"
  },
  typography: { color: '#1D6FB8', textTransform: 'none', fontSize: '16px', fontWeight: 700 },
  IconButton: { padding: '0 !important', marginRight: '8px', display: 'flex', alignItems: 'center', paddingBottom: '3px' },
  BoxStyle: { display: 'flex', alignItems: 'center', marginBottom: '10px' },
  typographyStyle: {
    whiteSpace: "normal", wordBreak: "break-word", fontSize: '16px', fontWeight: 700, color: '#0F172A'
  },
  secondaryText: { whiteSpace: "normal", wordBreak: "break-word", fontSize: '16px', fontWeight: 400, color: '#0F172A' },
  buttonBase: {
    position: 'relative', // Allows absolute positioning of the icon
    width: '100%',
    padding: '10px 40px', // Add padding to accommodate the icon
    textAlign: 'center', // Center text within the button
    display: 'flex', // Use flex for alignment
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center', // Center content vertically
  },
  activeButton: {
    backgroundColor: '#4caf50', // Example active color
    color: '#fff',
  },
  notactiveButton: {
    // backgroundColor: '#e0e0e0', // Example inactive color
    color: '#000',
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    height: "40px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    whiteSpace: "nowrap"
  },
  buttonText: {

  },
  filterIcon: {
    position: 'absolute',
    right: '10px', // Position icon at the right
    top: '50%',
    transform: 'translateY(-50%)', // Center icon vertically
  },
  activeButoon: {
    width: "100%",
    background: "#87C122",
    borderRadius: "50px",
    color: "#0F172A",
    height: "40px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  // notactiveButton: {
  //   color: "#475569",
  //   height: "40px",
  //   fontFamily: "Inter",
  //   fontWeight: 400,
  //   fontSize: "16px",
  //   lineHeight: "24px",
  //   textTransform: "capitalize" as "capitalize",
  //   whiteSpace: "nowrap"
  // },

  tabbox: {
    width: "100%",
    display: "flex",
    height: "100px"
  },

  buttonbox: {
    width: "70%",
    height: "72px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "16px"
  },

  singlebuttonbox: {
    width: "355px",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
  },

  choosedateimage: {
    width: "20px",
    height: "20px",
    paddingRight: "5px"
  }

}
// Customizable Area End
