Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.appointmentApiContentType = "application/json";

exports.appointmentAPiEndPoint = "appointment_management/availabilities";

exports.addAppointmentAPiMethod = "POST";
exports.deleteAllAppointmentsAPiMethod = "DELETE";
exports.getAppointmentListAPiMethod = "GET";

exports.format = "DD/MM/YY";
exports.serverFormat = "YY/MM/DD";
exports.webFormat = "dd/MM/yyyy";
exports.timeFormat = "HH:mm A";
exports.minDate = "01/01/20";
exports.maxDate = "01/01/25";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.availableDate = "Available Date";
exports.availableFrom = "Available From";
exports.availableTo = "Available To";
exports.viewAllAppointments = "View all Appointments";
exports.addAppointment = "Add Appointment";
exports.confirm = "Confirm";
exports.cancel = "Cancel";
exports.datePickerPlaceholder = "Select Date";
exports.datePickerPlaceholderFormat = "DD/MM/YY";
exports.timePickerPlaceholder = "Select Time";
exports.getList = "Get List";
exports.slot = "Slot";
exports.rideheader = "Rides"
exports.appointment= "Appointment ID"
exports.status = "Status"
exports.edittext = "Edit"
exports.cancelText = "Cancel"
exports.editappointment = "Edit Appointment Details"
exports.savetext = 'Save'
exports.cancelbutton = "Cancel";
exports.getApiMethod = "GET";
exports.patchApiMethod = "PATCH";
exports.getrideendpoint = "bx_block_appointment_management/appointments/all_appointments";
exports.acceptrideendpoint = "bx_block_appointment_management/appointments/update_status/";
exports.Accepted = "Ride Accepted Sucessfully";
exports.Rejected = "Ride Rejected";
exports.rejectedRide = "Reject Ride?";
exports.rejectedDec = "Are you sure you want to reject this ride? This action cannot be undone, and the order will be assigned to another driver.";
exports.reject = "Reject";
exports.accept = "Accept";
exports.acceptDec = "Are you sure you want to accept this ride? The user will be notified after confirmation.";
exports.acceptRide = "Accept Ride?";
exports.rideList = "Rides List";
// Customizable Area End
