import React from 'react';
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

import {crossIcon, group_map, ridenolonger}  from "./assets";
import {
  Typography,
  Dialog,
  Button,
  Box,
  styled,DialogContent,DialogActions,
  DialogTitle,
  Divider
} from "@mui/material";
import "../../email-account-registration/src/EmailAccountRegistratin.css"
import { avatard, star, taskcard } from "./assets";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// Customizable Area End

import AppointmentsController, {
  Props,
  configJSON,
} from './AppointmentsController';
import { TimeSlot } from './types';

export default class Appointments extends AppointmentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderAcceptDialog = () => {
    return (
      <>
        <StyledDialog open={false} maxWidth={false}>
          <CrossIconBox>
            <img src={crossIcon.default} />
          </CrossIconBox>
          <StyledDialogTitle>{configJSON.acceptRide}</StyledDialogTitle>
          <DialogCon>{configJSON.acceptDec}</DialogCon>
          <Divider />
          <CrossIconBox style={{paddingTop: "20px"}}>
            <CancleBtn variant="contained" style={{backgroundColor: "#E2E8F0", color: "#64748B"}} >
              {configJSON.cancelbutton}
            </CancleBtn>
            <AcceptBtn variant="contained" sx={{ ml: 1 }}>
              {configJSON.accept}
            </AcceptBtn>
          </CrossIconBox>
        </StyledDialog>
      </>
    )
  }

  renderRejectDialog = () => {
    return (
      <>
        <StyledDialog open={false} maxWidth={false}>
          <CrossIconBox>
            <img src={crossIcon.default} />
          </CrossIconBox>
          <StyledDialogTitle>{configJSON.rejectedRide}</StyledDialogTitle>
          <DialogCon>{configJSON.rejectedDec}</DialogCon>
          <Divider />
          <CrossIconBox style={{paddingTop: "20px"}}>
            <CancleBtn variant="contained" style={{backgroundColor: "#E2E8F0", color: "#64748B"}} >
              {configJSON.cancelbutton}
            </CancleBtn>
            <RejectBtn variant="contained" sx={{ ml: 1 }}>
              {configJSON.reject}
            </RejectBtn>
          </CrossIconBox>
        </StyledDialog>
      </>
    )
  }

  renderList = () => {
    return (
      <>
        <CardContainer>
          <CardSections>
            <HeaderTextBox>
              <HeaderText>
                Current Ride Requests
              </HeaderText>
            </HeaderTextBox>
            {true ?
              <>
                <CardContainer1>
                  <Box style={{ display: "flex" }}>
                    <InfoBox1>
                      <Box style={{ display: "flex", gap: "10px" }}>
                        <Avatar
                          src={avatard.default}
                          alt="Jane Deo"
                        />
                        <Box>
                          <TextHeader>
                            Janejah
                          </TextHeader>
                          <StarBox>
                            <img src={star.default} />
                            <p>4.8</p>
                          </StarBox>
                        </Box>
                      </Box>
                      <TextBody style={{ fontWeight: 700 }}>
                        Monday <br /> January 22, 2024 <br /> 08:00 AM
                      </TextBody>
                    </InfoBox1>

                    <InfoBox>
                      <TextHeader variant="body1">
                        Start:
                      </TextHeader>
                      <TextBody>
                        Maple Street, Springfield
                      </TextBody>
                    </InfoBox>

                    <InfoBox>
                      <TextHeader variant="body1">
                        Destination:
                      </TextHeader>
                      <TextBody>
                        2118 Thornridge Cir. Syracuse
                      </TextBody>
                    </InfoBox>

                    <InfoBox>
                      <TextHeader variant="body1">
                        Price:
                      </TextHeader>
                      <TextBody>
                        $12
                      </TextBody>
                    </InfoBox>

                    <InfoBox>
                      <TextHeader variant="body1">
                        Distance:
                      </TextHeader>
                      <TextBody>
                        18.3 mi
                      </TextBody>
                    </InfoBox>
                  </Box>
                  <Box>
                    <PriceDistanceBox>
                      <Box mt={2}>
                        <Buttonbox variant="outlined" style={{color: "#DC2626", border: "1px solid #DC2626"}} >
                          Reject
                        </Buttonbox>
                        <Buttonbox variant="contained" style={{background: "#1D6FB8", marginLeft: "10px"}}>
                          Accept
                        </Buttonbox>
                      </Box>
                    </PriceDistanceBox>
                  </Box>
                </CardContainer1>
              </>
              :
              <EmptyContainer>
                <Box width={432}>
                  <EmptyText>
                    There are no current ride requests. Stay tuned for new opportunities!
                  </EmptyText>
                </Box>
                <Box width={432}>
                  <img src={ridenolonger.default} width={"100%"} />
                </Box>
              </EmptyContainer>
            }
          </CardSections>

          <CardSections style={{ padding: "10px" }}>
            <img src={group_map} width={"100%"}/>
          </CardSections>
        </CardContainer>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <div style={webstyle.AppointmentOuter} className='outerTabs'>
        <NavigationMenu navigation={this.props.navigation} id={""} index={0}/>

        {this.renderList()}

        {this.renderAcceptDialog()}
        {this.renderRejectDialog()}

        <Snackbar
          open={this.state.isToastOpen}
          autoHideDuration={2000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert severity={this.state.rideRejected ? "error" : "success"} onClose={this.handleClose}
            sx={{ backgroundColor: this.state.rideRejected ? "red" : "green ", color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
            {this.state.rideRejected ? configJSON.Rejected : configJSON.Accepted}
          </MuiAlert>
        </Snackbar>      
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const CardContainer1 = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  borderRadius: "16px",
  boxShadow: theme.shadows[3],
  width: "100%",
  borderLeft: "5px solid #87C122",
  marginTop: "10px",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down(1100)]: {
    overflowX: "scroll"
  },
}));

const InfoBox = styled(Box)({
  flexGrow: 1,
  marginLeft: 12,
});

const InfoBox1 = styled(Box)({
  flexGrow: 1,
  // marginLeft: 12,
});

const HeaderTextBox = styled(Box)({
  paddingTop: "20px"
});

const HeaderText = styled("p")({
  fontSize: "30px",
  lineHeight: "40px",
  fontWeight: 700,
  fontFamily: "Inter",
  letterSpacing: "-0.15px"
});

const TextHeader = styled(Typography)({
  fontSize: "18px",
  fontWeight: 700,
  fontFamily: "Inter",
  lineHeight :"26px"
});

const TextBody = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: "Inter",
  lineHeight :"24px",
  marginTop: "10px"
});

const PriceDistanceBox = styled(Box)({
  textAlign: "right",
});
const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  padding: "15px 30px"
}));

const CardSections = styled(Box)(({ theme }) => ({
  width: "50%",
}));

const Buttons = styled(Button)({
  width:"136px"
});

const Avatar = styled("img")(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: '#ccc',
  width: "36px",
  height: "36px"
}));

const StarBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  alignItems: "center"
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "8px 8px 32px 8px",
    padding: theme.spacing(2),
    width: "600px",
    maxWidth: "600px",
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "32px"
});

const CrossIconBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end"
});

const DialogCon = styled(DialogContent)({
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "26px",
  overflowY: "hidden",
});

const CancleBtn = styled(Button)({
  width:"120px",
  height: "56px",
  fontSize: "16px",
  fontFamily: "inter",
  fontWeight: 700,
  lineHeight: "24px",
  color: "#64748B",
  backgroundColor: "#E2E8F0",
  border: "none",
  borderRadius: "8px",
  textTransform: "capitalize",
});

const AcceptBtn = styled(Button)({
  width:"120px",
  height: "56px",
  backgroundColor: "#1D6FB8",
  color: "#FFFFFF",
  border: "none",
  borderRadius: "8px",
  textTransform: "capitalize",
  fontSize: "16px",
  fontFamily: "inter",
  fontWeight: 700,
  lineHeight: "24px"
});

const RejectBtn = styled(Button)({
  width:"120px",
  height: "56px",
  backgroundColor: "#DC2626",
  color: "#FFFFFF",
  border: "none",
  borderRadius: "8px",
  textTransform: "capitalize",
  fontSize: "16px",
  fontFamily: "inter",
  fontWeight: 700,
  lineHeight: "24px"
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: "center",
  paddingBottom: "16px",
});

const EmptyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop:"50px"
}));

const EmptyText = styled(Typography)(({ theme }) => ({
  fontSize: '30px',
  fontWeight: 400,
  color: "#334155",
  fontFamily: "Inter",
  textAlign: "center"
}));

const DateTime = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
  fontSize: '8px',
  color: '#94A3B8',
  lineHeight: '1',
  marginLeft: '60px'
}));

const ResponsiveImageMap = styled('img')(({ theme }) => ({
  width: '95%',
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '90%', // 90% width for small screens
  },
  [theme.breakpoints.up('md')]: {
    width: '95%', // 95% width for medium and larger screens
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '10px', // Adjust padding for small screens
  },
}));

const Tabbox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column", 
  [theme.breakpoints.down('sm')]: {
    width: "100%", // Full width on small screens
  },
}));

const Buttonbox = styled(Button)(({ theme }) => ({
  width: "136px",
  height: "44px",
  background: "#FFFFFF",
  borderRadius: "8px",
  textTransform: "capitalize",
  paddingLeft: "5px", 
  paddingRight: "5px",
  fontFamily: "Inter",
  [theme.breakpoints.down('sm')]: {
    height: "50px", // Adjust height for small screens
    paddingLeft: "5px", 
    paddingRight: "5px",
  },
}));


const webstyle = {
  AppointmentOuter:{  margin: '0px', padding:'0px', },
  activeButoon: {
    width: "100%",
    background: "#ECFDF5",
    borderRadius: "50px",
    border:'1px solid #87C122',
    color: "#0F172A",
    height: "35px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  notactiveButton: {
    color: "#475569",
    height: "35px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  tabbox: {
    width: "100%",
    display: "flex",
    height: "100px"
  },

  buttonbox: {
    width: "70%",
    height: "72px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "16px"
  },

  singlebuttonbox: {
    width: "355px",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
  },

  choosedateimage: {
    width: "20px",
    height: "20px",
    paddingRight: "5px"
  }

}
// Customizable Area End
