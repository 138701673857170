import React from 'react';
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

import { chatIcon, close_icon, editicon, frame, group_icon, plusIcon, ridenolonger, taskcard } from "./assets";
import {
    Typography,
    Button,
    Box,
    styled, DialogActions,
    Divider,
    InputBase,
    alpha,
    Dialog,
    TextField
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import "../../email-account-registration/src/EmailAccountRegistratin.css"
// Customizable Area End

import AppointmentsController, {
    Props,
    configJSON,
} from './AppointmentsController';

export default class MyRide extends AppointmentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderList = () => {
        return (
            <>
                {true ?
                    <Box>

                        <ContainerSection>
                            {[1, 2, 3, 4, 5].map(() => {
                                return (
                                    <>

                                        <CardContainer1>
                                            <Box style={{ display: "flex", alignItems: "baseline" }}>
                                                <InfoBox>
                                                    <TextBody style={{ fontWeight: 700, marginTop: "0px" }}>
                                                        Monday <br /> January 22, 2024 <br /> 08:00 AM
                                                    </TextBody>
                                                </InfoBox>

                                                <InfoBox>
                                                    <TextHeader variant="body1">
                                                        Start:
                                                    </TextHeader>
                                                    <TextBody>
                                                        Maple Street, Springfield
                                                    </TextBody>
                                                </InfoBox>

                                                <InfoBox>
                                                    <TextHeader variant="body1">
                                                        Destination:
                                                    </TextHeader>
                                                    <TextBody>
                                                        2118 Thornridge Cir. Syracuse
                                                    </TextBody>
                                                </InfoBox>

                                                <InfoBox>
                                                    <TextHeader variant="body1">
                                                        Price:
                                                    </TextHeader>
                                                    <TextBody>
                                                        $12
                                                    </TextBody>
                                                </InfoBox>

                                                <InfoBox >
                                                    <img src={editicon.default}/>
                                                </InfoBox>
                                            </Box>
                                        </CardContainer1>
                                    </>
                                )
                            })}
                        </ContainerSection>
                    </Box>
                    :
                    <EmptyContainer>
                        <Box width={432}>
                            <EmptyText>
                                There are no current ride requests. Stay tuned for new opportunities!
                            </EmptyText>
                        </Box>
                        <Box width={432}>
                            <img src={ridenolonger.default} width={"100%"} />
                        </Box>
                    </EmptyContainer>
                }
            </>
        )
    }

    renderLeftFooter = () => {
        return (
            <>
             <FooterContainer>
                            <FooterText width={"386px"}>
                                Easily add your planned rides to your personal calendar and manage your schedule seamlessly.
                            </FooterText>
                            <FooterContainerLinks>
                                <img src={plusIcon} />
                                <FooterLinkText>
                                    Export to Google Calendar
                                </FooterLinkText>
                            </FooterContainerLinks>
                            <FooterContainerLinks>
                                <img src={plusIcon} />
                                <FooterLinkText>
                                    Export to Apple Calendar
                                </FooterLinkText>
                            </FooterContainerLinks>
                            <FooterContainerLinks>
                                <img src={plusIcon} />
                                <FooterLinkText>
                                    Export to Outlook
                                </FooterLinkText>
                            </FooterContainerLinks>
                        </FooterContainer>
            </>
        )
    }

    renderPaneltyDialog = () => {
        return (
            <>
                <StyledDialog open={false} maxWidth={false}>
                    <CrossIconBox>
                        <img src={close_icon} />
                    </CrossIconBox>
                    <Box style={{margin: "0px 20px"}}>
                        <img src={group_icon}/>
                        <StyledDialogTitle>Cancellation Penalty Applies</StyledDialogTitle>
                        <DialogDescription>You are about to cancel a confirmed ride. A penalty fee may be applied to your account.</DialogDescription>
                        <DialogDescription>Repeated cancellations may affect your standing as a driver.</DialogDescription>
                        <PaneltyBox>
                            <PaneltyFee>Penalty Fee:</PaneltyFee>
                            <PaneltyFee style={{ color: "red" }}>$116</PaneltyFee>
                        </PaneltyBox>
                    </Box>
                    <Divider />
                    <CrossIconBox style={{ paddingTop: "20px" }}>
                        <CancleBtn variant="contained" style={{ backgroundColor: "#E2E8F0", color: "#64748B" }} >
                        Keep the ride
                        </CancleBtn>
                        <RejectBtn variant="contained" sx={{ ml: 1 }}>
                        Confirm Cancellation
                        </RejectBtn>
                    </CrossIconBox>
                </StyledDialog>
            </>
        )
    }   
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <div style={webstyle.AppointmentOuter} className='outerTabs'>
                <NavigationMenu navigation={this.props.navigation} id={""} index={1} />

                <CardContainer>
                    <CardSections>
                        <HeaderTextBox>
                            <HeaderText>
                                {configJSON.rideList}
                            </HeaderText>
                            <Search>
                            <Autocomplete
                            options={[1,2,3]}
                            disablePortal
                            sx={{ width: 300 }}
                            data-test-id={"autoCompltee"}
                            renderInput={(params) => <TextField {...params} placeholder='Select status...' />}
                            />
                            </Search>
                        </HeaderTextBox>

                        {this.renderList()}
                        {this.renderLeftFooter()}

                    </CardSections>

                    <CardSections>
                        <RightContainer>
                            <RightPrimaryText>
                                You’ll reach your destination by 09:30 AM
                            </RightPrimaryText>
                            <RightSecondaryText>
                                Ride in progress
                            </RightSecondaryText>

                            <RightSection>
                                <ImageBox>
                                    <img src={frame} />
                                </ImageBox>
                                <Box>
                                    <ChatSectionBox>
                                        <RightText>
                                            Monday, January 22, 2024, at 08:00 AM
                                        </RightText>
                                        <Box>
                                            <img src={chatIcon} />

                                        </Box>
                                    </ChatSectionBox>

                                    <DestinationBox>
                                        <img src={taskcard.default} width={"35px"} height={"105px"} />
                                        <Box>
                                            <HeaderTitle>
                                                Start:
                                            </HeaderTitle>
                                            <BodyTitle style={{textDecoration: "underline"}}>
                                                Maple Street, Springfield
                                            </BodyTitle>
                                            <HeaderTitle>
                                                Destination:
                                            </HeaderTitle>
                                            <BodyTitle style={{textDecoration: "underline"}}>
                                                2118 Thornridge Cir. Syracuse
                                            </BodyTitle>
                                        </Box>
                                    </DestinationBox>

                                    <Box>
                                        <BodyTitle>
                                            Car type:
                                        </BodyTitle>
                                        <CarType>
                                            Wheelchair
                                        </CarType>

                                        <PriceBox>
                                            <PriceTitle>
                                                Total Distance
                                            </PriceTitle>

                                            <PriceAmount>
                                                12 Mi
                                            </PriceAmount>
                                        </PriceBox>

                                        <PriceBox>
                                            <PriceTitle>
                                                Base rate
                                            </PriceTitle>

                                            <PriceAmount>
                                                $80
                                            </PriceAmount>
                                        </PriceBox>

                                        <PriceBox>
                                            <PriceTitle>
                                                USD per mile
                                            </PriceTitle>

                                            <MultipleAmountBox>
                                                <MultipleAmount>$4.5 * 5km</MultipleAmount>
                                                <PriceAmount>
                                                    $36
                                                </PriceAmount>
                                            </MultipleAmountBox>
                                        </PriceBox>

                                        <PriceBox>
                                            <PriceTitle>
                                                Total
                                            </PriceTitle>

                                            <TotalAmount>
                                                $116
                                            </TotalAmount>
                                        </PriceBox>
                                    </Box>
                                </Box>
                            </RightSection>
                            <Box style={{display: "flex", justifyContent: "space-between"}}>
                            <AcceptBtn variant="contained" style={{ backgroundColor: "#FFF", color: "#DC2626" }} >
                        Cancle Ride
                        </AcceptBtn>
                        <AcceptBtn variant="contained" sx={{ ml: 1 }}>
                        End Ride
                        </AcceptBtn>
                            </Box>
                        </RightContainer>
                    </CardSections>
                </CardContainer>

                {this.renderPaneltyDialog()}

            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const ContainerSection = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "502px",
    overflowY: "auto",
    marginTop: "15px",
    // [theme.breakpoints.down(1100)]: {
    //     overflowX: "scroll"
    // },
    scrollbarWidth: "thin",
    scrollbarColor: "#4cae4f #eaf7ea",

    "&::-webkit-scrollbar": {
        width: "8px",
    },
    "&::-webkit-scrollbar-track": {
        background: "#eaf7ea",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#4cae4f",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#3b8e3e",
    },
}));

const CardContainer1 = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    borderRadius: "16px",
    boxShadow: theme.shadows[3],
    width: "98%",
    borderLeft: "5px solid #87C122",
    margin: "20px 0px",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down(1100)]: {
        overflowX: "scroll"
    },
    [theme.breakpoints.down(580)]: {
        padding: "12px"
    },
}));

const InfoBox = styled(Box)({
    flexGrow: 1,
    marginLeft: 12,
});

const InfoBox1 = styled(Box)({
    flexGrow: 1,
});

const HeaderTextBox = styled(Box)({
    display: "flex",
    height: "64px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px"
});

const HeaderText = styled("p")({
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 700,
    fontFamily: "Inter",
});

const TextHeader = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Inter",
    lineHeight: "26px",
    [theme.breakpoints.down(580)]: {
    fontSize: "14px",
    },
}));

const TextBody = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Inter",
    lineHeight: "24px",
    marginTop: "10px",
    [theme.breakpoints.down(580)]: {
        fontSize: "12px",
        },
}));

const PriceDistanceBox = styled(Box)({
    textAlign: "right",
});
const CardContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    padding: "15px 30px 100px",
        [theme.breakpoints.down(900)]: {
        flexDirection: "column"
    },
}));

const CardSections = styled(Box)(({ theme }) => ({
    width: "50%",
    // height: "100%",
    [theme.breakpoints.down(900)]: {
        width: "100%"
    },
}));

const Buttons = styled(Button)({
    width: "136px"
});

const Avatar = styled("img")(({ theme }) => ({
    borderRadius: '50%',
    backgroundColor: '#ccc',
    width: "36px",
    height: "36px"
}));

const StarBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "5px",
    alignItems: "center"
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
    [theme.breakpoints.down(500)]: {
        width: "60%"
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '244px',
    borderRadius: "8px",
    boder: "1px solid grey",
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        transition: theme.transitions.create('width'),
        paddingLeft: "5px",
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const FooterContainer = styled(Box)({
    marginTop: "20px"
});

const FooterContainerLinks = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    marginTop: "15px"
});

const FooterText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#64748B"
}));

const FooterLinkText = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#1D6FB8"
}));

const RightContainer = styled(Box)(({ theme }) => ({
    padding: "20px",
    borderRadius: "16px",
    border: "1px solid #E2E8F0",
    backgroundColor: "#FFF",
    marginLeft: "15px",
    [theme.breakpoints.down(900)]: {
        marginLeft: "0px"
    },
}));

const RightPrimaryText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#475569"
}));

const MultipleAmount = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#475569"
}));

const MultipleAmountBox = styled(Box)(({ theme }) => ({
    display:"flex",
    alignItems: "flex-end",
    flexDirection: "column"
}));

const RightSecondaryText = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#0F172A",
}));

const RightText = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#0F172A",
}));

const RightSection = styled(Box)({
    padding: "30px 20px"
});

const ChatSectionBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",

});

const DestinationBox = styled(Box)({
    display: "flex",
    marginTop: "10px",
    gap: "20px",
    alignItems: "flex-end"
});

const PriceBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px"
});

const PriceTitle = styled(Typography)({
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#0F172A",
});

const PriceAmount = styled(Typography)({
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#475569",
});

const TotalAmount = styled(Typography)({
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#34D399",
});

const HeaderTitle = styled(Typography)({
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#475569",
    margin: "5px 0px"
});

const BodyTitle = styled(Typography)({
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#1E293B",
    margin: "5px 0px"
});

const CarType = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#1E293B",
}));

const ImageBox = styled(Box)({
    width: "235px",
    height: "141px",
    margin: "0 auto",
    "& img": {
        width: "100%",
        height: "100%"
    }
});
const DialogDescription = styled(Typography)({
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    fontFamily: "Inter"
});

const DividerDialog = styled(Divider)({
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    overflowY: "hidden",
    paddingBottom: "0px"
});

const DialogButton = styled(Button)({
    width: "120px",
    height: "56px"
});

const CancleBtn = styled(Button)({
    width: "136px",
    height: "56px",
    backgroundColor: "#E2E8F0",
    color: "#64748B",
    border: "none",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontSize: "14px",
    fontFamily: "inter",
    fontWeight: 700,
    lineHeight: "24px"
});

const AcceptBtn = styled(Button)({
    width: "50%",
    height: "44px",
    backgroundColor: "#1D6FB8",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontFamily: "inter",
    fontWeight: 400,
    lineHeight: "24px"
});

const RejectBtn = styled(Button)({
    width: "200px",
    height: "56px",
    backgroundColor: "#DC2626",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontSize: "14px",
    fontFamily: "inter",
    fontWeight: 700,
    lineHeight: "24px"
});

const StyledDialogActions = styled(DialogActions)({
    justifyContent: "center",
    paddingBottom: "16px",
});

const EmptyContainer = styled(Box)(({ theme }) => ({
    marginTop: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}));

const EmptyText = styled(Typography)(({ theme }) => ({
    fontSize: '30px',
    fontWeight: 400,
    fontFamily: "Inter",
    textAlign: "center",
    color: "#334155",
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: "8px 8px 32px 8px",
      padding: theme.spacing(2),
      width: "600px",
      maxWidth: "600px",
    },
  }));
  
  const StyledDialogTitle = styled(Typography)({
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    fontFamily: "Inter",
    margin: "10px 0px"
  });
  
  const CrossIconBox = styled(Box)({
    display: "flex",
    justifyContent: "flex-end"
  });

  const PaneltyBox = styled(Box)({
    display: "flex",
    gap: "10px",
    margin: "15px 0px"
});
const StyledButton = styled(Button)({
    height: '46px',
    width: '155px',
    borderRadius: '9px',
    textTransform: 'none',
    fontSize: '18px'
});

const PaneltyFee = styled(Typography)({
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 700,
    fontFamily: "Inter",
});

const webstyle = {
    AppointmentOuter: { margin: '0px', padding: '0px', width: '100vw', height: '100vh', },

    choosedateimage: {
        width: "20px",
        height: "20px",
        paddingRight: "5px"
    },
    
    notactiveButton: {
        color: "#475569",
        height: "35px",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
        textTransform: "capitalize" as "capitalize",
        whiteSpace: "nowrap"
    },

    tabbox: {
        width: "100%",
        display: "flex",
        height: "100px"
    },

    activeButoon: {
        width: "100%",
        background: "#ECFDF5",
        borderRadius: "50px",
        border: '1px solid #87C122',
        color: "#0F172A",
        height: "35px",
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "24px",
        textTransform: "capitalize" as "capitalize",
        whiteSpace: "nowrap"
    },

    buttonbox: {
        width: "70%",
        height: "72px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        background: "#FFFFFF",
        borderRadius: "16px"
    },

    singlebuttonbox: {
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        width: "355px",
    },

}
// Customizable Area End
