import React from "react";

// Customizable Area Start
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  IconButton,
  styled,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SecurityIcon from "@mui/icons-material/Security";
import CallIcon from "@mui/icons-material/Call";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "25.56px",
  gap: '20px',
  '@media (max-width: 680px)': {
    width: '80%',
    padding: 2,
  },
};

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

import { carddetails, closeImg, renameImg, visa } from "./assets";





export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  homeAddress = () => {
    return (
      <FormContainer>
        <FormControl fullWidth>
          <MyFormLabel>{configJSON.address}</MyFormLabel>
          <Input fullWidth variant="outlined"
            placeholder="Address"
            value={this.state.userdetails?.rider_detail?.home_address || this.state.userdetails?.rider_detail?.home_address}
            type="text"
            InputProps={{
              readOnly: true,
            }}
          />

        </FormControl>
        <FormControl fullWidth >
          <MyFormLabel>{configJSON.contactname}</MyFormLabel>
          <Input fullWidth variant="outlined"
            placeholder="Name"
            value={`${this.state.userdetails?.first_name} ${this.state.userdetails?.last_name}`}
            type="text"
            InputProps={{
              readOnly: true,
            }}
          />

        </FormControl>
        <FormControl fullWidth >
          <MyFormLabel>{configJSON.Phonenumber}</MyFormLabel>
          <Input fullWidth variant="outlined"
            placeholder="Phone number"
            value={this.state.userdetails?.rider_detail?.mobile_number || this.state.userdetails?.rider_detail?.mobile_number}
            type="Text"
            InputProps={{
              readOnly: true,
            }}
          />

        </FormControl>
        <Cardbackbutton >
          <Nexteditbutton onClick={() => this.setState({ pagetoshow: "" })} data-test-id='backfromhomeaddressid'>
            <Button style={webstyle.nexttextcolor} >{configJSON.back}</Button>
          </Nexteditbutton>
        </Cardbackbutton>
      </FormContainer>
    )
  }

  settingMainpage = () => {
    return (
      <ListRight>
        <ListItemTextRight onClick={()=> this.handleNavigation('AddPayment')} data-test-id="cardetailspageid">
            <ListItemIconRightText>
              <img src={carddetails.default} />
            </ListItemIconRightText>
            <Typography style={{...webstyle.settingsOptionstext, color:"#0F172A"}}>{configJSON.Cardetails}</Typography>
            <IconButtonRight>
              <ArrowForwardIcon />
            </IconButtonRight>
          </ListItemTextRight>
          


        <ListItemTextRight onClick={() => this.redirectUser("TermsConditions")} data-test-id="terms-condition-redirectid">
          <ListItemIconRightText>
            <CommonIcon as={SecurityIcon} />
          </ListItemIconRightText>
          <Typography style={{...webstyle.settingsOptionstext, color:"#0F172A"}}>{configJSON.privacyAndPolicy}</Typography>
          <IconButtonRight>
            <ArrowForwardIcon />
          </IconButtonRight>
        </ListItemTextRight>

       <ListItemTextRight onClick={() => this.handleNavigation("ChangePassword")} data-testId = "navID">
          <ListItemIconRightText >
            <img src={renameImg.default} style={{ color: "#0F172A" }}/>
          </ListItemIconRightText>
          <Typography style={{...webstyle.settingsOptionstext, color:"#0F172A"}}>{configJSON.changePassword}</Typography>
          <IconButtonRight>
            <ArrowForwardIcon />
          </IconButtonRight>
        </ListItemTextRight>
        <ListItemTextRight onClick={this.handleLogoutmodal} data-test-id="logoutpoupid">
          <ListItemIconRightText >
            <CommonIcon as={ExitToAppIcon}/>
          </ListItemIconRightText>
          <Typography style={webstyle.settingsOptionstext}  data-testId={"logoutID"} >{configJSON.logOut}</Typography>
          <IconButtonRight>
            <ArrowForwardIcon />
          </IconButtonRight>
        </ListItemTextRight>
        <ListItemTextRight onClick={(this.handleOpen)} data-test-id="deletepoupid">
          <ListItemIconRightText >
            <CommonIcon as={DeleteOutlineIcon} style={{color:"#DC2626"}}/>
          </ListItemIconRightText>
          <Typography style={{...webstyle.settingsOptionstext, color:"#DC2626" }}  data-testId={"deleteID"} >Delete Account</Typography>
          <IconButtonRight>
            <ArrowForwardIcon />
          </IconButtonRight>
        </ListItemTextRight>
      </ListRight>
    )
  }

  cardDetails = () => {

    return (
      <>
        <Carddetailswrapper>
          {this.state.cardList?.map((item: any, index: any) => <Accordion style={webstyle.carddropdown}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              key={index}
            >
              <VisaType >
                <Box style={webstyle.onecarddetail}>
                  <img src={visa.default} />
                </Box>
                <Cardexpirened >
                  <Typography style={webstyle.endingvisatext}>{configJSON.visaend}</Typography>
                  <Typography style={webstyle.expirestext}>{configJSON.expiredate + item.expiry_date}</Typography>
                </Cardexpirened>
              </VisaType>
            </AccordionSummary>
            <AccordionDetails sx={webstyle.dropdetails}>
              <CardFormContainer>
                <FormControl fullWidth >
                  <MyFormLabel>{configJSON.creditnumber}</MyFormLabel>
                  <Input fullWidth variant="outlined"
                    placeholder="Credit card number"
                    type="text"
                    value={item.card_number}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth >
                  <MyFormLabel>{configJSON.expiry}</MyFormLabel>
                  <Input fullWidth variant="outlined"
                    placeholder="Expiry date"
                    type="text"
                    value={item.expiry_date}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth >
                  <MyFormLabel>{configJSON.cardname}</MyFormLabel>
                  <Input fullWidth variant="outlined"
                    placeholder="Card holder name"
                    type="Text"
                    value={item.cardholder_name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </CardFormContainer>
            </AccordionDetails>

          </Accordion>)}

        </Carddetailswrapper>
        <Cardbackbutton style={{ paddingTop: "20px" }}>
          <Cardpreviousbutton onClick={() => this.setState({ pagetoshow: "" })} data-test-id="backfromcardtomain">
            <Button style={webstyle.nexttextcolor} >{configJSON.back}</Button>
          </Cardpreviousbutton>
        </Cardbackbutton>
      </>


    )
  }

  riderContactuspage = () => {
    return (
      <Box>
        <Box style={webstyle.riderContactusMainBox}>
          <Box style={webstyle.riderContsctusinside}>
            <ListItemIconRightText style={{ paddingBottom: "41px" }}>
              <CommonIcon as={CallIcon} />
            </ListItemIconRightText>
            <Box style={{ ...webstyle.riderContactusMainBox, gap: "15px" }}>
              <Typography style={webstyle.contacttext}>{configJSON.contactUs}</Typography>
              <Typography style={webstyle.emailtext}>{this.state.userdetails?.rider_detail?.mobile_number || this.state.userdetails?.rider_detail?.mobile_number || "+2309843924"}</Typography>
            </Box>
          </Box>
          <Box style={webstyle.riderContsctusinside}>
            <ListItemIconRightText style={{ paddingBottom: "41px" }}>
              <CommonIcon as={AlternateEmailOutlinedIcon} />
            </ListItemIconRightText>
            <Box style={{ ...webstyle.riderContactusMainBox, gap: "15px" }}>
              <Typography style={webstyle.contacttext}>Email ID</Typography>
              <Typography style={webstyle.emailtext}>{this.state.userdetails?.email}</Typography>
            </Box>

          </Box>
        </Box>

        <Cardbackbutton style={{ paddingTop: "20px" }}>
          <Cardpreviousbutton onClick={() => this.setState({ pagetoshow: "" })} >
            <Button style={webstyle.nexttextcolor} >{configJSON.back}</Button>
          </Cardpreviousbutton>
        </Cardbackbutton>
      </Box>
    )

  }

  componenttoshow = () => {
    switch (this.state.pagetoshow) {
      case "":
        return this.settingMainpage()
      case "Address":
        return this.homeAddress()
      case "carddetails":
        return this.cardDetails()
      case "contactus":
        return this.riderContactuspage()
      default:
        break;
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BoxMain>
        {this.componenttoshow()}
        <div>
          <Modal
            open={this.state.openmodal}
            onClose={this.handleClosemodal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
            <Box style={{width:"100%", display:"flex", justifyContent: "flex-end"}}>
              <img src={closeImg.default} alt="close" onClick={this.handleClosemodal} />
              </Box>

              <SureBox>

              <SurityText >
                {configJSON.sureity}
              </SurityText>
              </SureBox>
              <BoxMessage>
              <MessageTypo>{configJSON.logoutMessage}</MessageTypo>
              </BoxMessage>
              <Box style={{width: "112%",maxWidth: "600px", paddingTop: "24px", paddingBottom: "24px"}}>
                <Box style={{ height:"1px", backgroundColor: "#E2E8F0"}}></Box>
              </Box>
              <Boxx>
                <Box style={webstyle.cancelButtonbox} onClick={this.handleClosemodal}>
                  <Typography style={webstyle.canceltext}>{configJSON.cancel}</Typography>
                </Box>
                <Box style={webstyle.logoutbuttonbox} onClick={this.handleLogout} data-test-id="handlelogoutid">
                  <CanceltextBox >{configJSON.logOut}</CanceltextBox>
                </Box>
              </Boxx>
            </Box>
          </Modal>
        </div>
        <Snackbar
          open={this.state.isToastOpen}
          autoHideDuration={2000}
          onClose={this.handletoastclose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert severity="success" onClose={this.handletoastclose}
            sx={{ backgroundColor: '#4caf50', color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
            {this.state.succesMessage}
          </MuiAlert>
        </Snackbar>
         <Modal
                    open={this.state.openDeleteModal}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-testID={"closeDeleteAccountModalID"}
                    style={{display:"flex", justifyContent:"center", alignItems:"center"}}
                  >
                    <DeleteBox>
                      <Box style={{width:"100%", display:"flex", justifyContent: "flex-end"}}>
                      <img src={closeImg.default} alt="close" onClick={this.handleClose} />
                      </Box>
        
                      <BoxCenter>
                        <WrappeDBox >
        
                        <TypographyDelete data-testID={"cancleTextID"}>
                          Are you sure you want to delete your account?
                        </TypographyDelete>
                        </WrappeDBox>
                      </BoxCenter>
        
                      <BoxCenter style={{flexDirection: "column"}}>
                        <TypographySure>
                          This action can't be undone, and all your account data will be permanently deleted and inaccessible.
                        </TypographySure>
        
                      <Box style={{width: "112%",maxWidth: "650px", paddingTop: "24px", paddingBottom: "24px"}}>
                                      <Box style={{ height:"1px", backgroundColor: "#E2E8F0"}}></Box>
                                    </Box>
                      </BoxCenter>
                      
        
                      <BoxDeleteModal>
                        <Box style={{paddingTop:"24px", paddingBottom: "24px"}}>
                          <Box style={{ width:"100%", height: "1px", border: "1px solid #E2E8F0",}}>
        
                          </Box>
                        </Box>
                        <BoxCenter>
                          <CancelButton onClick={this.handleClose}>
                            {configJSON.cancel}
                          </CancelButton>
                          <DeleteBTN onClick={this.handleDelete} data-testID={"deleteaccountid"}>
                          Delete Account
                          </DeleteBTN>
                        </BoxCenter>
                      </BoxDeleteModal>
                    </DeleteBox>
                  </Modal>
      </BoxMain>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DeleteBox = styled(Box)({
  boxSizing: "border-box",
  overflow: "hidden",
  margin: "0px",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "650px",
  backgroundColor: "#ffffff",
  borderRadius: '24.89px',
  padding: "30px 15px",
  "@media (max-width: 600px)": {
    width: "80%",
  },
});

const BoxCenter = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const WrappeDBox = styled(Box)({
  width:"87%",
  "@media (max-width: 960px)": {
    width:"100%",
    textAlign: "center"
  }
})
const SureBox = styled(Box)({
  width:"100%",
  "@media (max-width: 960px)": {
    textAlign: "center"
  }
})

const TypographyDelete = styled(Typography)({
  fontWeight: 700,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: "24px",
  marginTop: "16px",
  fontFamily: "Inter",
  lineHeight: "32px",
  color: "#0F172A",
  letterSpacing: "-0.5%",
  width:"80%",
  "@media (max-width: 960px)": {
    fontSize: "20px",
    width:"100%",
  }
})

const BoxMessage = styled(Box)({
  width:"100%",
  "@media (max-width: 960px)": {
  textAlign: "center"
  }
})

const TypographySure = styled(Typography)({
  marginTop: "16px",
  marginBottom: "16px",
  color: "#0F172A",
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "26px",
  paddingLeft: "40px",
  paddingRight: "40px",
  "@media (max-width: 960px)": {
    fontSize: "15px",
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
  }
})

const BoxDeleteModal = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: "20px",
  "@media (max-width: 960px)": {
    marginTop: "10px",
    justifyContent: "center",
  }
});
const MessageTypo = styled(Typography)({
  fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26px",
    letterSpacing: "0%",
    width: "90%",
    "@media (max-width: 960px)": {
    width:"100%"
  }
})

const CancelButton = styled(Button)({
  width: "120px",
  height: "56px",
  marginRight: '22px',
  backgroundColor: "#E7E5E4 !important",
  color: "#64748B",
  borderRadius: "8px",
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  textTransform: "capitalize" as "capitalize",
  "@media (max-width: 960px)": {
    width: "100px",
    boxSizing: "border-box",
    margin: "0px 5px"
  }
});

const DeleteBTN = styled(Button)({
  width: "153px",
  height: "56px",
  marginRight: '22px',
  backgroundColor: "#DC2626 !important",
  color: "#FFFFFF",
  borderRadius: "8px",
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: "16px",
  textTransform: "capitalize" as "capitalize",
  lineHeight: "24px",
  "@media (max-width: 960px)": {
    width: "100px",
    boxSizing: "border-box",
    margin: "0px 5px"
  }
});

const ArrowForwardIcon = styled(ArrowForwardIosIcon)({
  color: "#0F172A",
  height: "15px",
});

const Cardexpirened = styled(Typography)({
  display: "flex",
  flexDirection: "column",
  gap: "10px"
});

const VisaType = styled(Typography)({
  display: "flex",
  gap: "10px"

});

const Carddetailswrapper = styled(Typography)({
  display: "flex",
  flexDirection: "column",
});


const Cardbackbutton = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Nexteditbutton = styled(Box)({
  background: "",
  color: "black",
  width: "35%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid  #1D6FB8",
  borderRadius: "8px",
  height: "56px",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const Cardpreviousbutton = styled(Box)({
  color: "black",
  width: "35%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid  #1D6FB8",
  borderRadius: "8px",
  height: "56px",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const FormContainer = styled(Box)({
  "& .css-1hf16px-MuiFormControl-root-MuiTextField-root": {
    border: "none"
  },
  margin: "0",
  width: "95%",
  padding: "10px",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

const CardFormContainer = styled(Box)({
  "& .css-1hf16px-MuiFormControl-root-MuiTextField-root": {
    border: "none"
  },
  margin: "0",
  padding: "10px 0px 0px 0px",
  width: "100%",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

const Input = styled(TextField)({
  marginBottom: '16px',
  position: "relative",
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: "#ffffff",
  },
});

const MyFormLabel = styled(FormLabel)({
  color: '#334155',
  fontWeight: 700,
  fontSize: "14px",
  marginBottom: "5px",
  fontFamily: "Inter",
  lineHeight: "22px"
});

const BoxMain = styled(Box)({
  minHeight: "100vh",
  backgroundColor: "#ECFDF5",
  "@media (max-width: 960px)": {
    minHeight: "auto",
  },
});

const IconButtonRight = styled(IconButton)({
  marginLeft: "auto",
});

const CommonIcon = styled("svg")({
  color: "#0F172A",
});

const ListRight = styled(List)({
  width: "95%",
  background: "#FFFFFF",
  color: "#94A3B8",
  borderRadius: "1rem",
  padding: "10px",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

const ListItemIconRightText = styled(ListItemIcon)({
  minWidth: "37px",
  cursor: 'pointer !important'

});

const ListItemTextRight = styled(ListItem)({
  padding: "27px 10px",
  cursor: 'pointer !important',
  pointerEvents: 'auto',
});

const SurityText = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "32px",
  color: "#0F172A",
  "@media (max-width: 680px)": {
    fontSize: "20px"
  },
  "@media (max-width:550px)": {
    fontSize: "18px"
  }
});

const CanceltextBox = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  cursor: "pointer",
  color: "#FFFFFF",
  "@media (max-width: 371px)": {
    fontSize: "22px"
  },

});
const Boxx = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  gap:"16px",
  "@media (max-width: 960px)": {
    justifyContent: "center",
  }
})

const webstyle = {
  logouttext: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "25.56px",
    lineHeight: "38.33px",
    color: "#0F172A"
  },

  settingsOptionstext: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0F172A"
  },

  carddropdown: {
    width: "95%",
    borderRadius: "16px",
    boxShadow: 'none',
    border: 'none'
  },

  riderContsctusinside: {
    height: "90px",
    width: "70%",
    background: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px"
  },

  addcarddetailswrapper: {
    display: "flex",
    flexDirection: "column" as 'column',
    gap: "20px"
  },

  addpaymentfirstbox: {
    width: "95%",
    background: "white",
    borderRadius: "16px",
    height: "78px",
    display: "flex",
    alignItems: "center",
  },

  coinimage: {
    display: "flex",
    justifyContent: "center"
  },

  onecarddetail: {
    width: "66.67px",
    height: "48px",
    borderRadius: "10px",
    border: "1px solid #6C7278",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  riderContactusMainBox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "25px"
  },

  prefilledcarddetails: {
    paddingLeft: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px"
  },

  paypalbox: {
    paddingLeft: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    position: "relative" as "relative"
  },

  cardnumberbox: {
    background: "white",
    borderRadius: "16px",
    height: "87px",
    display: "flex",
    alignItems: "center",
  },

  paymentinnerchildbox: {
    paddingLeft: "16px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    // background: "white",
    borderRadius: "16px",
    height: "100%",
    position: "relative" as "relative"
  },

  cardnumberinnexbox: {
    paddingLeft: "16px",
    display: "flex",
    borderRadius: "16px",
    height: "100%",
    position: "relative" as "relative",
    alignItems: "center",
    gap: "10px",
    background: "white",
  },

  dropdetails: {
    backgroundColor: '#ECFDF5',
    padding: "0px"
  },

  addpaymenttext: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#0F172A"
  },

  prefilledcardbox: {
    width: "95%",
    background: "white",
    borderRadius: "16px",
    height: "78px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },

  expirycardnamebox: {
    height: "87px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "space-between"
  },

  contacttext: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0F172A",
  },

  paypalidtext: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#334155",
    flex: 1,
    height: "100%",
    borderRadius: "8px",
    border: "none",
    outline: "none",
    width: "20%"
  },

  emailtext: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#334155",
  },

  sureitytext: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "25.56px",
    lineHeight: "38.33px",
    color: "#0F172A"
  },

  cancelButtonbox: {
    width: "100%",
    maxWidth: "120px",
    height: "56px",
    background: "#E7E5E4",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center'
  },

  logoutbuttonbox: {
    width: "100%",
    maxWidth: "94px",
    background: "#DC2626",
    height: "56px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center'
  },

  canceltext: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#64748B",
    cursor: "pointer"
  },

  savetextcolor: {
    color: "#ECFDF5",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    cursor: "pointer",
    textTransform: "capitalize" as "capitalize"
  },

  endingvisatext: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "19.36px",
  },

  expirestext: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.36px",
  },

  nexttextcolor: {
    color: "#1D6FB8",
    textTransform: "capitalize" as "capitalize",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    cursor: "pointer"
  },

  labelText: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#334155"
  },

  formTextField: {
    width: '100%',
    maxWidth: "440px",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "white",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000"
  },
  saveBtn: {
    maxWidth: "440px",
    height: "56px",
    width:"100%",
    backgroundColor: "#1D6FB8",
    borderRadius: "8px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26px",
    color: "#FFFFFF",
    textTransform: "capitalize" as "capitalize",
  },
  cancelBtn: {
    maxWidth: "440px",
    width:"100%",
    height: "56px",
    borderRadius: "8px",
    color: "#1D6FB8",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26px",
    backgroundColor: "white",
    border: "1px solid #1D6FB8 ",
    textTransform: "capitalize" as "capitalize",
  },
  backText: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#334155"
  },
  cardText: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "40px",
    color: "#334155"
  }
}

// Customizable Area End
