// Customizable Area Start
import React from "react";
import TutorialPageController,{ configJSON, Props } from "./TutorialPageController";
import { Box, Typography, Button, styled, Slider } from "@mui/material"
import { backArrow, playIcon } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web"
// Customizable Area End

// Customizable Area Start

const Backbutton = styled(Box)({
  background: "#FFFFFF",
  color: "black",
  width: "50%",
  display: "flex",
  height: "56px",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid  #1D6FB8",
  borderRadius: "8px",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const Helpcenterbuttobox = styled(Box)({
  width: "70%",
  display: "flex",
  position: "absolute",
  top: "115%",
  paddingBottom:"20px",
  flexDirection: "column" as "column",
  gap: '10px',
  alignItems: "center",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

// Customizable Area End

export default class TuturialPage extends TutorialPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  helpCenterTutorials = () => {
    let videoUrl = this.state.videoData?.data?.[0].attributes.tutorial_video.url
    return (
      <Box style={webstyle.tutorialwrapper}>
        <video
          ref={this.videoRef}
          width="90%"
          height="100%"
          src={videoUrl}
          onTimeUpdate={this.handleTimeUpdate}
          onClick={this.handlePlayPause}
          data-test-id="videostartmanage"
        />

        <Box style={webstyle.videocontrolbox}>
            <Box
              style={{...webstyle.playpausebox, backgroundColor: `${ !this.state.isPlaying  ? 'rgba(0, 0, 0, 0.7)' : ""}`}}
              onClick={this.handlePlayPause}
              >
              {!this.state.isPlaying && (
              <img src={playIcon.default } alt="Play"  style={webstyle.playIconstyle}/>
            )}
            </Box>
          <Slider
            max={this.videoRef.current?.duration || 0}
            value={this.state.currentTime}
            onChange={this.handleSeek}
            data-test-id = "handleseektime"
            sx={{
              width: '95%',
              cursor: 'pointer',
              position: "absolute",
              bottom: "10px",
              margin: '0 auto',
              height: "4px",
              color: "#87C122",
              '& .MuiSlider-rail': {
                backgroundColor: '#0F172A',
              },
            }}
          />
        </Box>
        <Box style={webstyle.buttonboxcontainer}>
          <Helpcenterbuttobox >
            <Backbutton onClick={() => this.handleRouteNavigation("SideMenuBasic")} data-testId="backbtnID">
              <Button style={webstyle.backtextcolor} >{configJSON.back}</Button>
            </Backbutton>
          </Helpcenterbuttobox>

        </Box>
      </Box>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
            <NavigationMenu navigation={this.props.navigation} id={""} index={-1} imageurl={""} />
       <Box>
              <Box style={{ paddingTop: "12px", paddingBottom: "24px", paddingLeft:"40px" }}>
                <Box style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }}
                data-testId = 'backToSettingId'
                onClick={()=> this.handleRouteNavigation("SideMenuBasic")}>
                  <img src={backArrow.default}    />
                  <Typography style={webstyle.backText}>{configJSON.back}</Typography>
                </Box>
                <Typography style={webstyle.cardText}>Help Center - Tutorials</Typography>
              </Box>

       <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        {this.helpCenterTutorials()}
        </Box> 
       </Box>
      </>


      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webstyle = {

    cardText: {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
        letterSpacing: "-0.5%",
        color: "#0F172A"
    },
    backText: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "0%",
        color: "#0F172A"
    },

  helpcenterOptionbox: {
    width: "200px",
    height: "200px",
    border: "1px solid #E2E8F0",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonboxcontainer: {
    justifyContent: "center",
    paddingBottom: "15px",
    width: "100%",
    display: "flex",
  },

  backtextcolor: {
    color: "#1D6FB8",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    textTransform: "capitalize" as "capitalize",
  },

  tutorialwrapper: {
    textAlign: 'center' as 'center',
    height: "100%",
    background: "white",
    borderRadius: "16px",
    width: "100%",
    maxWidth: "1097px",
    maxHeight:"555px",
    position: "relative" as 'relative'
  },

  videocontrolbox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
  },

  playpausebox: {
    position: "absolute" as 'absolute',
    width:"100%",
    top: "0px",
    maxWidth: "1097px",
    maxHeight:"555px",
    height: "100%",
    borderRadius: "16px"
  },
  playIconstyle: {
    width: "72px",
    height: "72px",
    position: "absolute" as 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: "40%",
    left:"40%",
    cursor: "pointer",
  }
}
// Customizable Area End