import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";

import Button from '@mui/material/Button';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web"

// Customizable Area End

import ChangePasswordController, {
  Props,
  configJSON,
} from "./ChangePasswordController";

import {  backArrow } from "./assets";





export default class ChangePassword extends ChangePasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  changePasswordscreen = () => {
    return (
        <MainContainer>
            <Box style={{width:"93%", paddingTop: "12px"}}>
                    <Box style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }} data-testId = 'backbtnId' onClick={() => this.redirectUser("SideMenuBasic")} >
                      <img src={backArrow.default}  />
                      <Typography style={webstyle.backText}>{configJSON.back}</Typography>
                    </Box>
                    <Typography style={webstyle.cardText}>Change Password</Typography>
                  </Box>
      <FormContainer>
        <FormControl fullWidth >
          <MyFormLabel>{configJSON.prevpass}</MyFormLabel>
          <Input fullWidth variant="outlined"
            onChange={this.Handleprevpass}
            placeholder="**********"
            type={"password"}
            value={this.state.prevpass}
            sx={{

            }}

          />
          {this.state.oldpassunmatch && <PasswordMatcherror>
            {configJSON.prevpasserror}
          </PasswordMatcherror>}
         
        </FormControl>
        <FormControl fullWidth >
          <MyFormLabel>{configJSON.newpass}</MyFormLabel>
          <Input fullWidth variant="outlined"
            type={"password"}
            data-test-id='handle-password-validate'
            onChange={this.handleDriverPasswordChange}
            placeholder="**********"
            value={this.state.password}
          />
          <PasswordcriteriaValidationText>
            {this.state.passwordCriteria.hasUpperCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
            {configJSON.hasUpperCase}
          </PasswordcriteriaValidationText>
          <PasswordcriteriaValidationText>
            {this.state.passwordCriteria.hasLowerCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
            {configJSON.hasLowerCase}
          </PasswordcriteriaValidationText>
          <PasswordcriteriaValidationText>
            {this.state.passwordCriteria.hasNumber ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
            {configJSON.hasNumber}
          </PasswordcriteriaValidationText>
          <PasswordcriteriaValidationText>
            {this.state.passwordCriteria.hasSpecialChar ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
            {configJSON.hasSpecialChar}
          </PasswordcriteriaValidationText>
          <PasswordcriteriaValidationText>
            {this.state.passwordCriteria.isValidLength ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
            {configJSON.minimumChar}
          </PasswordcriteriaValidationText>

        </FormControl>
        <FormControl fullWidth style={{ paddingTop: "19px" }}>
          <MyFormLabel>{configJSON.comfirmpass}</MyFormLabel>
          <Input fullWidth variant="outlined"
            onChange={this.handleConfirmpassowrd}
            data-test-id="handle-confirm-passowrd"
            placeholder="**********"
            type={"password"}
            value={this.state.confirmPassword}
            InputProps={{
              style: {
                borderRadius: '8px',
                background: "white",
              },
              inputProps: {
                style: {
                  margin: "0px"
                },
              }
            }}
          />
          {this.state.passwordMatcherror && <PasswordMatcherror>
            {configJSON.unmatchpass}
          </PasswordMatcherror>}
        </FormControl>
        <Box style={webstyle.drivereditbuttonboxcontainer}>
          <Passowrdbuttobox >
            <SaveeditPasswordbutton onClick={this.HandleSavepassword} data-test-id='handle-save-password'>
              <Button style={webstyle.savetextcolor} >{configJSON.save}</Button>
            </SaveeditPasswordbutton>
            <Nexteditbutton onClick={() => this.redirectUser("SideMenuBasic")} data-test-id="backtomainpageid">
              <Button style={webstyle.nexttextcolor} >{configJSON.cancel}</Button>
            </Nexteditbutton>
          </Passowrdbuttobox>

        </Box>
      </FormContainer>
      </MainContainer>

    )
  }


  render() {
    return (
      // Customizable Area Start
      <BoxMain>
      <NavigationMenu navigation={this.props.navigation} id={""} index={-1} imageurl={""}/>
        {this.changePasswordscreen()}
      </BoxMain>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const Circle = styled("span")({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "15px",
  height: "15px",
  borderRadius: "50%",
  fontSize: "12px",
  color: "white",
  fontWeight: "bold",
  marginRight: "8px",
});

const CircleGreen = styled(Circle)({
  backgroundColor: "#34D399",
  textAlign: "center"
});

const CircleRed = styled(Circle)({
  backgroundColor: "#DC2626",
  textAlign: "center"
});

const PasswordcriteriaValidationText = styled(Typography)({
  fontSize: "12px",
  color: "#000000",
  marginTop: "10px",
  display: "flex",
  alignItems: "center",
  fontFamily: "Inter",
  fontWeight: 400
});

const PasswordMatcherror = styled(Typography)({
  fontSize: "12px",
  color: "#DC2626",
  display: "flex",
  alignItems: "center",
  marginTop: "-6px"
});

const Passowrdbuttobox = styled(Box)({
  width: "100%",
  display: "flex",
  gap: '10px',
  flexDirection: "column" as "column",
  alignItems: "center",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

const MainContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 960px)": {
      width: "100%",
    }
  });


const SaveeditPasswordbutton = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  height: "56px",
  background: "#1D6FB8",
  color: "black",
  width: "100%",
  display: "flex",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const Nexteditbutton = styled(Box)({
  background: "",
  color: "black",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  border: "1px solid  #1D6FB8",
  borderRadius: "8px",
  height: "56px",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const FormContainer = styled(Box)({
  "& .css-1hf16px-MuiFormControl-root-MuiTextField-root": {
    border: "none"
  },
  margin: "0",
  width: "100%",
  maxWidth: "440px",
  "@media (max-width: 960px)": {
    width: "90%",
  }
});


const Input = styled(TextField)({
  marginBottom: '16px',
  position: "relative",
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: "#ffffff",
    height: "41px",
    paddingTop: "10px",
    paddingBottom: "5px",
    paddingLeft: "8px",
  },
});

const MyFormLabel = styled(FormLabel)({
  color: '#334155',
  fontWeight: 700,
  fontSize: "14px",
  marginBottom: "5px",
  fontFamily: "Inter",
  lineHeight: "22px"
});

const BoxMain = styled(Box)({
  minHeight: "100vh",
  backgroundColor: "#ECFDF5",
  "@media (max-width: 960px)": {
    minHeight: "auto",
  },
});




const webstyle = {

  drivereditbuttonboxcontainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "15px",
    paddingTop: "29px"
  },

  savetextcolor: {
    color: "#ECFDF5",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    cursor: "pointer",
    textTransform: "capitalize" as "capitalize"
  },

  nexttextcolor: {
    color: "#1D6FB8",
    textTransform: "capitalize" as "capitalize",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    cursor: "pointer"
  },

  backText: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#334155"
  },
  cardText: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "40px",
    color: "#334155"
  }
}

// Customizable Area End
