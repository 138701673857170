// @ts-nocheck
import React from "react";

// Customizable Area Start
import PlanYourRide from "./planyourride/PlanYourRide.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ChooseDatesTime from "./choosedate/ChooseDatesTime.web";
import UpcomingRides from "./upcomingrides/UpcomingRides.web";
import SearchLocation from "./choosefrommap/SearchLocation.web";
import ChooseFromMap from "./choosefrommap/ChooseFromMap.web";
import { choseedate, sucessDialog } from "./assets";
import {
  Typography,
  // Customizable Area Start
  Container,
  Button,
  Box,
  styled,
  Modal
  // Customizable Area End
} from "@mui/material";


// Customizable Area End

import ProjectTemplatesController, {
  Props,
  configJSON,
} from "./ProjectTemplatesController";

export default class ProjectTemplates extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  buttonTabs = () => {
    return (
      <>
        <Tabbox >
          <Buttonbox >
            <Singlebuttonbox >
              <Button style={this.state.activeTab == '1' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("1")} data-test-id="choosedateid">
                 Plan Your ride</Button>
            </Singlebuttonbox>
            <Singlebuttonbox >
              <Button style={this.state.activeTab == '2' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("2")} data-test-id="planyourrideid">Choose dates</Button>
            </Singlebuttonbox>
            <Singlebuttonbox>
              <Button style={this.state.activeTab == '3' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("3")} data-test-id="upcomingrideid">Upcoming Rides</Button>
            </Singlebuttonbox>
          </Buttonbox>
        </Tabbox>
      </>

    )
  }

  screentab = () => {
    switch (this.state.activeTab) {
      case '1':
        return <PlanYourRide opensearch={this.openSearch} setactive={this.setactiveTabchoosedate} planride={this.callbackPlanYouride}  rideId={this.state.rideId}
        fromTripHistoryScreen={this.state.fromTripHistoryScreen}
        />

      case '2':
        return <ChooseDatesTime planyourridedata={this.state.planyourridedata} handleSuccessclose={this.handleSucessClose} handleSucessopen={this.handleSucessOpen} getid={this.callbackRideid}/>

      case '3':
        return <UpcomingRides onDataReceived={this.handleDataReceived} />

      default:
        return <PlanYourRide  opensearch={this.openSearch} setactive={this.setactiveTabchoosedate} planride={this.callbackPlanYouride}  rideId={this.state.rideId}
        fromTripHistoryScreen={this.state.fromTripHistoryScreen}/>
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{ boxSizing: 'border-box', margin: 0, padding: 0, width: '100vw', height: '100vh', overflowX: 'hidden' }}>
        <NavigationMenu navigation={this.props.navigation} id={""} index={0} />
        {this.state.showpage == "planride" && <BoxMainContainer>
          {this.buttonTabs()}
          {this.screentab()}
          <Modal
            open={this.state.SucessModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testId={'closeSucessModalID'}
          >
            <ErrorBox2>
              <BoxCenter>
                <SucessImg src={sucessDialog.default} alt="error" />
                <TypographySucess data-testId={"emptyErrorId"}>{configJSON.sucessMessage}</TypographySucess>
                <TypographyDouble2>{configJSON.Sucessdescription}</TypographyDouble2>
                <ButtonReturn2 onClick={this.handleSucessClose}>
                  <TypographyRetry2 style={{ cursor: "pointer" }}>{configJSON.done}</TypographyRetry2>
                </ButtonReturn2>
              </BoxCenter>
            </ErrorBox2>
          </Modal>
        </BoxMainContainer>}
        {this.state.showpage == "ordersummary" &&  <ChooseFromMap backTofirstpage={this.backTofirstpage} navigation={this.props.navigation} rideid={this.state.rideid}/>}
        {this.state.showpage == "choosefrommap" &&  <SearchLocation backTofirstpage={this.backTofirstpage}/>}

      </div>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ButtonReturn2 = styled(Box)(({ theme }) => ({
  background: '#1D6FB8',
  '&:hover': {
    backgroundColor: '#559ADC',
  },
  color: '#ffff',
  width: '25%',
  height: '54px',
  borderRadius: '8px',
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '25%', // Increase width for smaller screens
    height: '34px', // Decrease button height for smaller screens
  },
}));
const TypographyRetry2 = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '400',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px', // Decrease font size on smaller screens
  },
}));

const ErrorBox2 = styled(Box)(({ theme }) => ({
  width: '35%',
  overflow: 'hidden',
  border: '0px solid white',
  backgroundColor: '#FFFFFF',
  padding: '25px',
  borderRadius: '24.89px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  margin: '0px',
  [theme.breakpoints.down('md')]: {
    width: '70%', // Adjust the width for medium-sized screens
  },
  [theme.breakpoints.down('sm')]: {
    width: '75%', // Adjust for smaller screens
    padding: '20px', // Decrease padding on smaller screens
  },
}));

const BoxCenter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const SucessImg = styled('img')(({ theme }) => ({
  width: '70%',
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '200px', // Adjust the image size for smaller screens
    height: '150px',
  },
}));

const BoxMainContainer = styled(Box)(({ theme }) => ({
 padding: "40px",
 overflow:"hidden",
  [theme.breakpoints.down('sm')]: {
 padding: "20px",
  },
}));

const TypographySucess = styled(Typography)(({ theme }) => ({
  color: 'var(--Color-1, #87C122)',
  fontSize: '30px',
  fontWeight: '700',
  marginTop: '30px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px', // Decrease font size on smaller screens
  },
}));

const TypographyDouble2 = styled(Typography)(({ theme }) => ({
  color: '#475569',
  fontSize: '20px',
  fontWeight: '400',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px', // Adjust for smaller screens
  },
}));

const Buttonbox = styled(Box)({
  width: "100%",
  height: "72px",
  display: "flex",
  paddingLeft: "10px",
  paddingRight: "10px",
  background: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "16px",
  "@media (max-width: 800px)": {
    width: "80%",
  },
  "@media (max-width: 700px)": {
    width: "90%",
  },
  "@media (max-width: 575px)": {
    width: "90%",
    flexDirection: "column",
    height: "150px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
});

const Tabbox = styled(Box)({
  width: "100%",
  display: "flex",
  height: "100px",
  "@media (max-width: 575px)": {
    height: "auto",
    width: "92%",
    display:"none"
  },
});


const Singlebuttonbox = styled(Box)({
  width: "355px",
  display: "flex",
  justifyContent: 'center',
  alignItems: "center",
  "@media (max-width: 430px)": {
    width: "300px",
  },
  "@media (max-width: 380px)": {
    width: "250px",
  },
});




const webstyle = {
  activeButoon: {
    width: "100%",
    background: "#87C122",
    borderRadius: "50px",
    color:"#FFFFFF",
    height: "40px",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily:" 'Inter', sans-serif",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  notactiveButton: {
    color: "#0F172A",
    height: "40px",
    fontFamily:" 'Inter', sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  tabbox: {
    width: "100%",
    display: "flex",
    height: "100px"
  },

  buttonbox: {
    width: "70%",
    height: "72px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "16px"
  },

  singlebuttonbox: {
    width: "355px",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
  },

  choosedateimage: {
    width: "20px",
    height: "20px",
    paddingRight: "5px"
  }

}
// Customizable Area End