export const choseedate = require('../assets/choosedate.png');
export const appointment = require('../assets/appointment.png');
export const map = require('../assets/map.png');
export const editicon = require("../assets/icon.png")
export const edit = require("../assets/edit.png")
export const deleteicon = require("../assets/delete.png")
export const date = require("../assets/date.png")
export const location = require("../assets/location.png")
export const time = require("../assets/time.png")
export const droplocation = require("../assets/droplocation.png")
export const ridenolonger = require("../assets/mobile_popover__group_.png")
export const avatard = require("../assets/task_card__No_avatar_.png")
export const star = require("../assets/star_24px.png")
export const taskcard = require("../assets/task_card.png")
export const crossIcon = require("../assets/button_icon.png")
export const plusIcon = require("../assets/image.png").default
export const frame = require("../assets/Frame.png").default
export const chatIcon = require("../assets/chat_image.png").default
export const close_icon = require("../assets/close.png").default
export const group_icon = require("../assets/group_icon.png").default
export const group_map = require("../assets/group_map.png").default



