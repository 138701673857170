export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const arrow = require("../assets/Backbutton.png").default;
export const logo = require("../assets/cuate.png").default;
export const sendOtp = require("../assets/bro.png").default;
export const retry = require("../assets/retry.png").default;
export const errorImage = require("../assets/error.png").default;
export const codeExpireImage = require("../assets/expire.png").default;
export const allSetImage = require("../assets/success.png").default;
export const back = require("../assets/back.png").default;