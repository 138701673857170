import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  helpcenterQA?: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  helpCentreQA: any;
  token: string;
  queue_type: any;
  dataQA: [];
  dataSub: [];
  showpage: string;
  showtutorials: boolean
  isPlaying: boolean,
  currentTime: number,
  volume: number,
  videoData: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TutorialPageController extends BlockComponent<Props, S, SS> {
  getVideoCallId: string = ''
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.videoRef = createRef();

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
        token: "",
        helpCentreQA: [],
        dataQA: [],
        queue_type: "",
        showpage: "helpcenter",
        dataSub: [],
        isPlaying: false,
        showtutorials: false,
        volume: 1,
        currentTime: 0,
      videoData: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getVedio()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recieved", JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getVideoCallId){
        this.setState({ videoData: responseJson  })
      }
    }


    // Customizable Area End
  }

  // Customizable Area Start
  videoRef: any;
  handlePlayPause = () => {
    const video = this.videoRef.current;
    if (this.state.isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    this.setState((prevState) => ({ isPlaying: !prevState.isPlaying }));
  };

  handleTimeUpdate = () => {
    this.setState({ currentTime: this.videoRef.current.currentTime });
  };

  handleSeek = (e: any) => {
    const seekTime = e.target.value;
    this.videoRef.current.currentTime = seekTime;
    this.setState({ currentTime: seekTime });
  };


  handleRouteNavigation = (route: string) => {
    localStorage.setItem("redirectfrom", "Help Center")
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate)
}
  

  getVedio() {

    let token = localStorage.getItem('token')
    console.log("token1234", token)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVideoCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVideoEndpoint
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
