import React from "react";

// Customizable Area Start
import {
    Box,
    styled,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    IconButton,
    Snackbar,
    Button,
    Modal,
    Container,
    Paper,
    Grid,
    TextField
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { cameraImage, closeImg, doller,edit,profile, uploadIcon } from "./assets";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationMenu from './NavigationMenu';
import Setting from "../../settings2/src/Settings2.web";
import SideMenuController, {
    configJSON,
} from "./SideMenuController.web";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import CommuteIcon from '@mui/icons-material/Commute';
import DateRangeIcon from '@mui/icons-material/DateRange';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import HelpCentre from "../../helpcentre/src/HelpCentre.web";
import UserProfile from "../../user-profile-basic/src/UserProfileBasicBlock.web";
import FavLocation from "../../location/src/FavLocation.web";
import WalletScreen from "../../stripepayments/src/WalletScreen.web";
import PaymentScreen from "../../stripepayments/src/PaymentScreen.web";
import PaymentMethod from "../../stripepayments/src/PaymentMethod.web";
import AppointmentRider from "../../appointmentmanagement/src/AppointmentRider.web";
import NotificationUIDriver from "../../notifications/src/NotificationUIDriver.web";
import NotificationUIRider from "../../notifications/src/NotificationUIRider.web";
import TripHistory from "../../tasks/src/TripHistory.web";
// Customizable Area End

export default class SideMenu extends SideMenuController {

    // Customizable Area Start
    renderRightSection = () => {
        const userloggedin = localStorage.getItem("role_id");
        switch (this.state.selectedSection) {
            case "appointment":
                return <AppointmentRider navigation={undefined} id={""} />;
            case `${configJSON.editProfile}`:
                return<>
                {
                this.state.selectedSection !== `${configJSON.settings}` &&this.state.selectedSection !== `Payments`&& this.state.selectedSection !== `${configJSON.notifications}` && this.state.selectedSection !== "appointment" &&<Header>
                {this.profileContainer()}
                </Header>
                 }
                 <UserProfile navigation={this.props.navigation} id={""} uploadImageState={this.state.uploadImageState} uploadImageFunction={this.handleUploadImage}  handleFileChange={this.handleFileChange} imagefile={this.state.imagefile} updateProfile={this.updateResponse} removePhoto={this.removeUrl}/>
                </>
            case `${configJSON.payments}`:
                if (this.state.paymentDetails) {
                    return (
                      <PaymentScreen
                      navigation={this.props.navigation}
                      id={""}
                      onSave={this.handleShowPayment} // Go back to WalletScreen
                      />
                    );
                  }
            
                  if (this.state.showDetails) {
                    return (
                    <PaymentMethod
                    navigation={this.props.navigation}
                    id={""}
                    onSave={this.handleShowPayment}/>
                    );
                  }
            
                  return (
                    <WalletScreen
                      navigation={this.props.navigation}
                      id={""}
                      onDetailsClick={this.handleShowDetails}
                      onPaymentDetailsClick={this.handleShowPaymentDetails} 
                      selectedSection={this.state.selectedSection}
                      setSelectedSection={this.setSelectedSection}
                    />
                  );
                
            case `${configJSON.favLocation}`:
                return <FavLocation navigation={undefined} id={""} />;
                case `${configJSON.notifications}`:
                    if (userloggedin === "driver") {
                        return (
                            <NotificationUIDriver
                                selectedSection={this.state.selectedSection}
                                setSelectedSection={this.setSelectedSection}
                                navigation={this.props.navigation}
                                id={""}
                            />
                        );
                    } else if (userloggedin === "rider") {
                        return (
                            <NotificationUIRider
                                selectedSection={this.state.selectedSection}
                                setSelectedSection={this.setSelectedSection}
                                navigation={this.props.navigation}
                                id={""}
                            />
                        );
                    }
                    break;
            case `${configJSON.helpCenter}`:
                return <Box style={{ marginTop:"40px" }}><HelpCentre navigation={this.props.navigation} id={""} /></Box>;
            case `${configJSON.settings}`:
                return <Setting navigation={this.props.navigation} id={""} redirectedit={this.personalinfo}/>;
                case `${configJSON.tripHistory}`:
                    return <Box style={{ marginTop:"40px" }}> <TripHistory navigation={this.props.navigation} id={""} /> </Box>
                case `${configJSON.carInfromation}`:
                    return (
                        <Box style={{ marginTop:"40px", width:"100%" }} >
                        {this.renderCarInfo()}
                        </Box> )
        }
    };

    renderCarInfo = () => {

        let { drivingLicenseNumber, autoInsurancePolicyNumber, autoInsuranceDocument,
            dmvDocument, drivingLicensePicture, vehicleType, vehicleMake, vehicleModel,
            vehicleYear, numberOfSeats, vehicleLicensePlate} = this.state.carInitialFormData
        return(
                    <form onSubmit={(event: any) => this.handleSubmit(event)} data-testId = 'sumitID' >
            <Container  style={{ width:"100%", backgroundColor: "#ECFDF5", boxShadow:"none"}}>
                <Paper elevation={3} style={{ backgroundColor: "#ECFDF5", boxShadow:"none" }}>
                    <Grid container spacing={2} style={{ backgroundColor: "#ECFDF5", boxShadow:"none" }}>
                        <Grid item xs={12} sm={6}>
                            <TypographyEdit>Driving licence number</TypographyEdit>
                            <TextFieldWrap fullWidth 
                            onChange={this.handleCarInfoData}
                            value={drivingLicenseNumber}
                            name='drivingLicenseNumber'
                                InputProps={{
                                    // readOnly: true,
                                    endAdornment: (
                                        <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                                    )
                                }}
                                variant="outlined" />
                                {this.state.setErrors.drivingLicenseNumber && <TypographyError>{this.state.setErrors.drivingLicenseNumber}</TypographyError>}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TypographyEdit>Auto Insurance Policy Number</TypographyEdit>
                            <TextFieldWrap fullWidth 
                            value={autoInsurancePolicyNumber}
                            onChange={this.handleCarInfoData}
                            name="autoInsurancePolicyNumber"
                                InputProps={{
                                    endAdornment: (
                                        <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                                    )
                                }}
                                variant="outlined" />
                                 {this.state.setErrors.autoInsurancePolicyNumber && <TypographyError>{this.state.setErrors.autoInsurancePolicyNumber}</TypographyError>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TypographyEdit>Auto Insurance Document</TypographyEdit>

                            <TextFieldWrap fullWidth 
                            value={autoInsuranceDocument}
                            name="autoInsuranceDocument"
                                InputProps={{
                                    endAdornment: (
                                        <img src={uploadIcon.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                                    )
                                }}
                                // type="file"
                                  />
                                 {this.state.setErrors.autoInsuranceDocument && <TypographyError>{this.state.setErrors.autoInsuranceDocument}</TypographyError>}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TypographyEdit>DMV Document Upload</TypographyEdit>

                            <TextFieldWrap fullWidth 
                            value={dmvDocument}
                            name="dmvDocument"
                            
                                InputProps={{
                                    endAdornment: (
                                        <img src={uploadIcon.default} style={{ width: "20px", height: "20px" }} alt="edit" onClick={this.handleRef} />
                                    )
                                }}
                                
                                 />
                                 {this.state.setErrors.dmvDocument && <TypographyError>{this.state.setErrors.dmvDocument}</TypographyError>}

                                <input type='file' name="dmvDocument" ref={this.videoRef} onChange={this.handleCarFileChange} style={{display:"none"}} />

                                
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TypographyEdit>Driving License Picture Upload</TypographyEdit>

                            <TextFieldWrap fullWidth 
                            value={drivingLicensePicture}
                            name="drivingLicensePicture"
                                InputProps={{
                                    endAdornment: (
                                        <img src={uploadIcon.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                                    )
                                }}
                                // type="file" 
                                 />
                                 {this.state.setErrors.drivingLicensePicture && <TypographyError>{this.state.setErrors.drivingLicensePicture}</TypographyError>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TypographyEdit>Vehicle Type</TypographyEdit>

                            <TextFieldWrap fullWidth 
                                onChange={this.handleCarInfoData}
                                value={vehicleType}
                                name="vehicleType"
                                InputProps={{
                                    endAdornment: (
                                        <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                                    )
                                }}
                                variant="outlined"  />
                             {this.state.setErrors.vehicleType && <TypographyError>{this.state.setErrors.vehicleType}</TypographyError>}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TypographyEdit>Vehicle Make</TypographyEdit>

                            <TextFieldWrap fullWidth 
                            value={vehicleMake}
                            name='vehicleMake'
                            onChange={this.handleCarInfoData}
                                InputProps={{
                                    endAdornment: (
                                        <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                                    )
                                }}
                                variant="outlined" />
                             {this.state.setErrors.vehicleMake && <TypographyError>{this.state.setErrors.vehicleMake}</TypographyError>}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TypographyEdit>Vehicle Model</TypographyEdit>
                            <TextFieldWrap fullWidth 
                            value={vehicleModel}
                            name="vehicleModel"
                            onChange={this.handleCarInfoData}
                                InputProps={{
                                    endAdornment: (
                                        <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                                    )
                                }}
                                variant="outlined" />
                             {this.state.setErrors.vehicleModel && <TypographyError>{this.state.setErrors.vehicleModel}</TypographyError>}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TypographyEdit>Vehicle year</TypographyEdit>
                            <TextFieldWrap fullWidth 
                            value={vehicleYear}
                            name="vehicleYear"
                            onChange={this.handleCarInfoData}
                                InputProps={{
                                    endAdornment: (
                                        <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                                    )
                                }}
                                 variant="outlined" />
                             {this.state.setErrors.vehicleYear && <TypographyError>{this.state.setErrors.vehicleYear}</TypographyError>}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TypographyEdit>Number of Seats</TypographyEdit>

                            <TextFieldWrap fullWidth 
                            value={numberOfSeats}
                            name="numberOfSeats"
                            onChange={this.handleCarInfoData}
                                InputProps={{
                                    endAdornment: (
                                        <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                                    )
                                }}
                                variant="outlined" />
                             {this.state.setErrors.numberOfSeats && <TypographyError>{this.state.setErrors.numberOfSeats}</TypographyError>}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TypographyEdit>Vehicle License Plate</TypographyEdit>

                            <TextFieldWrap fullWidth 
                            onChange={this.handleCarInfoData}
                            value={vehicleLicensePlate}
                            name='vehicleLicensePlate'
                                InputProps={{
                                    endAdornment: (
                                        <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                                    )
                                }}
                                 variant="outlined"  />
                             {this.state.setErrors.vehicleLicensePlate && <TypographyError>{this.state.setErrors.vehicleLicensePlate}</TypographyError>}

                        </Grid>
                    </Grid>
                </Paper>
                <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'} sx={{pt:"40px"}}>

                <SaveBtn type="submit">Save</SaveBtn>
                </Box>
            </Container>
            </form>
        );
      
    }

    handleShowDetails = () => {
        this.setState({ showDetails: true, paymentDetails: false });
      };
    
      handleShowPayment = () => {
        this.setState({ showDetails: false, paymentDetails: false });
      };
    
      handleShowPaymentDetails = () => {
        this.setState({ showDetails: false, paymentDetails: true });
      };
      
      textReturn = () => {
        return `${ (this.state.selectedSection == configJSON.settings) ? "0px" : "40px"}`
      }
    handleData = () => {
        const driverMenuItems = [
            { text: `${configJSON.editProfile}`, icon: <PermIdentityIcon /> },
            { text: `${configJSON.carInfromation}`, icon: <CommuteIcon /> },
            { text: `${configJSON.tripHistory}`, icon: <DateRangeIcon /> },
            { text: `${configJSON.payments}`, icon: <CreditCardOutlinedIcon /> },
            { text: `${configJSON.notifications}`, icon: <NotificationsNoneIcon /> },
            { text: `${configJSON.helpCenter}`, icon: <HelpOutlineIcon /> },
            { text: `${configJSON.settings}`, icon: <SettingsOutlinedIcon /> },
        ];

        const riderMenuItems = [
            { text: `${configJSON.editProfile}`, icon: <PermIdentityIcon /> },
            { text: `${configJSON.tripHistory}`, icon: <DateRangeIcon /> },
            { text: `${configJSON.helpCenter}`, icon: <HelpOutlineIcon /> },
            { text: `${configJSON.settings}`, icon: <SettingsOutlinedIcon /> },
        ];

        let data = this.state.idUserRole === "driver" ? driverMenuItems : riderMenuItems;
        return data;
    }

    profileContainer = () =>{
        return (
            <>
              <ProfileContainer data-testId={"visibleImageModalID"}
              >
                <ProfileWrapper>
                <Box style={{ backgroundColor: "#D1FAE5", width: "156px", height: "156px", borderWidth: "4px", borderRadius: "75px",
                    position: "relative",
                 }}
                >
                    { this.state.btnStatus && <Box style={{ position:"absolute", display:"flex", flexDirection: "column",
                        justifyContent:"center", alignItems: "center", zIndex:"1111", top:"57px", left:"36px"}}
                        onMouseLeave={this.showEditbtn}
                        >
                        <img src={cameraImage.default} style={{ height:"24px", width:"24px" }} />
                        <Typography style={{zIndex: "999999", color:"white",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: "14px",
                            lineHeight: "22px",
                            letterSpacing: "0%"
                            
                        }}
                        onClick={this.handleUploadImage}
                        >Edit Picture</Typography>
                        </Box> }
                        <FaceIcon 
                        data-testId = "imageId"
                         onMouseEnter={this.showEditbtn}
                         style={{ filter:`blur(${this.state.btnStatus ? "1px" : ""})`}}
                             >
                                {!this.state.imageUrl ? <Typography
                            style={{ fontFamily: "Inter",
                                fontWeight: 600,
                                fontSize: "56px",
                                lineHeight: "48px",
                                color: "#34D399"
                                }}
                            >{`${this.state.userFirstName.charAt(0).toUpperCase()}`}</Typography> :<img src={this.state.imageUrl} style={{width:"156px", height:"156px", borderRadius: '50%'}} />}
                            
                            </FaceIcon>
                </Box>
                <Box style={{display:"flex", justifyContent: "center", flexDirection: "column"}}>
                        <TypoEdit>At least 800x800 px recommended. JPG or PNG is allowed.</TypoEdit>
                        <StyledBtn 
                        onClick={
                            this.state.imageUrl ? this.handleOpenModal : this.handleUploadImage
                        }
                        >{`${!this.state.imageUrl ? 'Upload Photo' : "Delete Photo" }`}</StyledBtn>
                        </Box>
                </ProfileWrapper>
                        </ProfileContainer>

                        <Box width={'100%'} justifyContent={"center"} alignItems={'center'}>
            <ModalWrapper open={this.state.openModal} onClose={this.handleCloseModal} >
              <StyledModal>
                <Box style={{padding: "24px"}}>
                    <Box display={"flex"} justifyContent={'flex-end'} width={"100%"}>
                        <img src={closeImg.default} style={{height: "32px", width:"32px"}}
                        onClick={this.handleCloseModal} 
                        />
                    </Box>
                <Typography  style= {webstyle.deleteText } >
                  Delete Photo?
                </Typography>
                <Typography variant="body1" mt={1} style= {webstyle.sureText }>
                Are you sure you want to delete the photo? This action cannot be undone
                </Typography>
                </Box>
                <Box style={{ width:"100%"}}>
                    <Box style={{border: "1px solid #E2E8F0"}}></Box>
                </Box>
                <Box style={{padding:"24px"}}>

                <Box display="flex" justifyContent="flex-end">
                  <Button  style= {webstyle.cancelBtn }  onClick={this.handleCloseModal} sx={{ flex: 1, mr: 1 }}>
                    Cancel
                  </Button>
                  <Button  style= {{...webstyle.cancelBtn, backgroundColor: "#DC2626", color: "#FFFFFF" } }
                   color="error" onClick={this.removeUrl} 
                   sx={{ flex: 1 }}>
                    Delete
                  </Button>
                </Box>
                </Box>
              </StyledModal>
            </ModalWrapper>
          </Box>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id={""} index={-1} imageurl={this.state.imageUrl}/>
                {
                    this.state.selectedSection === "Payments" && <Header2>
                        <WalletSection>
                            <Typography style={{ color: "#0F172A", fontSize: '26px', fontWeight: 700 }}>
                                My Wallet
                            </Typography>
                            <WalletInfo>
                                <DollarImage src={doller.default} alt="Dollar" />
                                <Typography style={{ color: "#0F172A", fontSize: '20px', fontWeight: 700 }}>
                                    25000
                                </Typography>
                            </WalletInfo>
                        </WalletSection>
                        <ProfileImage src="https://randomuser.me/api/portraits/men/75.jpg" alt="Profile" />
                    </Header2>
                }
                <IconButtonForToogle onClick={this.handleToggleSidebar} style={{ display: window.innerWidth <= 960 ? `${configJSON.block}` : `${configJSON.none}` }}>
                    <MenuIcon />
                </IconButtonForToogle>

            <FullContainer sx={{ marginTop: (this.state.selectedSection == `${configJSON.settings}` || this.state.selectedSection == "appointment") ? `${configJSON.px40}` : `${configJSON.px0}` }}>
                {/** Hide Sidebar when notifications section is selected */}
                {
                    this.state.selectedSection !== `${configJSON.notifications}` &&
                    (this.state.selectedSection !== 'Payments' ||
                      (this.state.paymentDetails === true && !this.state.showDetails)) &&    (
                        <Sidebar
                            style={{
                                display: window.innerWidth > 960 || this.state.isSidebarOpen
                                    ? `${configJSON.block}`
                                    : `${configJSON.none}`,
                                    backgroundColor: '#ECFDF5', 
                                    position: 'relative', 
                                    borderTopLeftRadius: '15px',
                                    borderTopRightRadius: '15px', 
                                    borderBottomLeftRadius: '10px', 
                                    borderBottomRightRadius: '10px',
                                    marginTop: this.textReturn()
                            }}
                        >
                            <List component="nav" data-testId={"featureBtnId"}
                              style={{
                                backgroundColor: 'white', // Set the List background color to white
                                borderTopLeftRadius: '15px', // Round top corners of the list items area
                                borderTopRightRadius: '15px', // Round top corners of the list items area
                                borderBottomLeftRadius: '15px', // Bottom-left border radius
                                borderBottomRightRadius: '15px', // Bottom-right border radius
                                paddingBottom:'70px',
                                marginTop: "34px"
                            }}>
                                {this.handleData().map((item: any) => (
                                    <ListItemLeft
                                        key={item.text}
                                        >
                                        <StyledBox
                                         key={item.text}
                                         style={{ borderLeft: (this.state.selectedSection === item.text) ? "1px solid #87C122" : "" }}
                                         data-testId = "styledBoxId"
                                         onClick={this.handleSectionChange.bind(this,item.text)}>
                                        <ListItemIconSideBar>
                                            <StyledIcon style={{
                                                color: this.state.selectedSection === item.text
                                                    ? `${configJSON.color1}`
                                                    : `${configJSON.color2}`,
                                            }}>
                                                {item.icon}
                                            </StyledIcon>
                                        </ListItemIconSideBar>
                                        <ListItemText
                                            primary={item.text}
                                            style={{
                                                color:
                                                    this.state.selectedSection === item.text
                                                        ? `${configJSON.color1}`
                                                        : `${configJSON.color2}`,
                                            }}
                                        />
                                        </StyledBox>
                                    </ListItemLeft>
                                ))}
                            </List>
                            
                        </Sidebar>
                    )
                }

                <BoxList>{this.renderRightSection()}</BoxList>
                <Snackbar
            open={this.state.istoastopen2}
            autoHideDuration={5000}
            onClose={this.handleClosetoast}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert severity={"error"} onClose={this.handleClosetoast}
              sx={{ backgroundColor:  "rgb(208, 52, 52)", color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
             Invalid image type. Only JPG, JPEG, and PNG are allowed.
            </MuiAlert>
          </Snackbar>
            </FullContainer>
        </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const FullContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 960px)": {
        flexDirection: "column",
        marginTop: "30px",
    }
});

const TextFieldWrap = styled(TextField)({
        backgroundColor: '#ffffff',
        marginBottom: '16px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',
          backgroundColor: "#ffffff",
          border: '1px solid #CBD5E1'
        },
})

const BoxList = styled(Box)({
    width: '75%',
     marginTop: "34px",
    "@media (max-width: 960px)": {
        width: '80%',
        margin: "0px auto"
    }
});

const ProfileContainer = styled(Box)({
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "1.5px solid #ffffff",
    borderRadius: "50%",
    width: "156px",
    height: "156px",
    marginRight: "20px",
    "@media (max-width: 960px)": {
    marginBottom: "100px"
    }

});

const ProfileWrapper = styled(Box)({
    display:"flex",
    gap:"20px" ,
    width:"100%",
    maxWidth:"453px",
    maxHeight:"153px",
    height: "100%",
    "@media (max-width: 960px)": {
        flexDirection: "column",
    },
})

const CameraIcon = styled("img")({
    width: "15px",
    height: "15px",
    marginBottom: "11px",
    zIndex: 2,
    position: "absolute"
});

const FaceIcon = styled(Box)({
    width: "156px",
    height: "156px",
    position: "relative",
    display:"flex",
    alignItems:"center",
    justifyContent: "center",
    zIndex: 1,
    borderRadius: '50%'
});

const TypographyEdit = styled(Typography)({
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0%",
    color: "#334155",
    paddingBottom: "5px"
});

const TypographyError = styled(Typography)({
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "22px",
    letterSpacing: "0%",
    color: "red"
});

const SaveBtn = styled(Button)({
    maxWidth: "440px",
    width:"100%",
    height: "56px",
    borderRadius: '8px',
    backgroundColor: "#1D6FB8 !important",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    color:"#FFFFFF"
})

const Header = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    padding: '33px',
    borderRadius: '10px',
});
const Header2 = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#87C122",
    padding: "33px",
    borderRadius: "10px",
    margin: "20px 35px",
    "@media (max-width: 600px)": {
      flexDirection: "row",
      alignItems: "center",
    },
  });
  
  const WalletSection = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "@media (max-width: 600px)": {
      alignItems: "center",
    },
  });
  
  const WalletInfo = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  });
  
  const ProfileImage = styled("img")({
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    border:'2px solid white',
    "@media (max-width: 600px)": {
      alignSelf: "center",
      marginTop: "15px",
    },
  });
  
  const DollarImage = styled("img")({
    width: "20px",
    height: "20px",
    borderRadius:'50%'
  });
  
const IconButtonForToogle = styled(IconButton)({
    marginTop: '6px',
    marginLeft: '10px',
    display: "none",
    "@media (max-width: 960px)": {
        display: "flex",
    },
});

const Sidebar = styled(Box)({
    width: '20%',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
    display: 'flex',
    justifyItems: 'center',
    margin: '0px 40px',
    '@media (max-width: 960px)': {
        margin: '20px auto',
        width: '80%',
        marginLeft: '30px'
    },
});

const StyledIcon = styled("div")({
    color: "#0F172A",
    margin: '0 15 0 13'
});

const ListItemLeft = styled(ListItem)({
    padding: '18px 33px',
    alignItems: 'center',
    cursor: 'pointer',
});

const StyledBox = styled(Box)({
    padding: '18px 33px',
    height:"59px",
    borderRadius:"8px",
    display:"flex",
    justifyContent:"center",
    alignItems: "center",
    width:"auto"
});

const TypoEdit = styled(Typography)({
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0%",
    color: "#475569",
    width: "300px",
    marginBottom: "11px"

});

const StyledBtn = styled(Button)({
    width: "128px",
    height: "32px",
    borderRadius: "8px",
    paddingTop: "6px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0%",
    color: "#000000",
    backgroundColor: "#FFFFFF !important",
    textTransform: "capitalize"
});


const ListItemIconSideBar = styled(ListItemIcon)({
    minWidth: '37px',
});

const StyledModal = styled(Box)({
    maxWidth: 600,
    width:"100%",
    maxHeight: "288px",
    height:"100%",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    display:"flex",
    flexDirection:"column",
    justifyContent: "center",
"@media (max-width: 960px)": {
       width:"80%",
       height: "auto",
       maxHeight: "none"
    }
   
})

const ModalWrapper = styled(Modal)({
    display:"flex",
    justifyContent:"center",
     alignItems: "center",
     "@media (max-width: 960px)": {
       justifyContent:"flex-start",
       width: "80%"
    }
})
const webstyle = {
    deleteText: {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "-0.5%",
        color: "#0F172A",
        paddingBottom: "16px"
    },
    cancelBtn: {
        maxWidth: "120px",
        width: "100%",
        height: "56px",
        borderRadius: "8px",
        backgroundColor: "#E2E8F0",
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#64748B",
        textTransform: "capitalize" as "capitalize"
    },
    sureText: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px",
        color: "#0F172A",
        width: "69%"
    }
}

// Customizable Area End
