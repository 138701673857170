
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { tickSign } from "../assets";
const carData = [
    { id: 1, name: 'Sedan', image: 'car1.jpg', distance: '15 km', price: '22' },
    { id: 2, name: 'SUV', image: 'car2.jpg', distance: '20 km', price: '80' },
    { id: 2, name: 'Hatchback', image: 'car2.jpg', distance: '20 km', price: '80' },
    { id: 2, name: 'Minivan', image: 'car2.jpg', distance: '20 km', price: '80' },
    { id: 2, name: 'Other', image: 'car2.jpg', distance: '20 km', price: '80' },
    // Add more cars as needed
];;
interface CarAttributes {
    car_type: string;
    distance: number;
    car_image: {
      url: string;
      file_name: string;
    };
  }
  interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
  }
  interface Geometry {
    location: {
      lat: number;
      lng: number;
    };
  }
  interface Vehicle {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      base_rate: string;
      per_mile_rate: string;
      image: string;
    };
    isSelected: boolean;
    tickSign: string;
  }
  interface Orgin{
    lat: number,
    lng: number,
}
export interface AutocompleteType {
getPlace: () => google.maps.places.PlaceResult;
addListener: (event: string, callback: Function) => void;
setBounds: (bounds: google.maps.LatLngBounds) => void;
}

  interface Place {
    address_components: AddressComponent[];
    formatted_address: string;
    geometry: Geometry;
    place_id: string;
    html_attributions: string[];
    utc_offset: () => void;
  }
  
  interface CarResponse {
    id?: string;
    type?: string;
    attributes?: CarAttributes;
  }
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    opensearch: () => {};
    setactive: () => {};
    planride: (data:any) => {};
    rideId?: string | null;
    fromTripHistoryScreen?: boolean|null;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    helpCentreFaq: any;
    expandedField3: boolean;
    expandedField4: boolean;
    expandedField5: boolean;
    expandedField6: boolean;
    direction:any,
    startorigin:Orgin | null;
    endorigin:Orgin | null;
    autoCompleteStart:AutocompleteType | null
    autoCompleteEnd:AutocompleteType | null
    isStartDestOpen: boolean;
    additionalField:Vehicle[] | null;
    additionalData:Vehicle | null
    home: any;
    work: any;
    changeBorder:boolean;
    changeBorder2:boolean;
    location: any;
    selectedCar: any
    isCarPopUpOpen: boolean;
    LocationErrrorPopUp: boolean;
    InternetErrorPopUp: boolean;
    startlocation: string;
    destinationlocation: string;
    showstartlocation: boolean;
    showendLocation: boolean;
    startlocationerror: boolean;
    endlocationerror: boolean;
    cartypeerror: boolean;
    isToastOpen: boolean;
    fieldClicked:boolean;
    isToastOpen1:boolean;
    rideId:any;
    ordersummarydetails:any;
    fromTripHistoryScreen:boolean;
    carData:CarResponse[];
    selectedCar2:any;
    staticLocations:any;
    dynamicLocations:any;
    anchorEl:any;
    selectedId:number | null;
    favLoc:boolean;
    deletePopUp:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}
export default class PlanYourRideController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    orderSummaryApiCallId:any;
    favPlacesApiCallId:any;
    getCarPopUpApiCallId:any;
    deletefavPlacesApiCallId:any;
    riderVehicalApiCallId:string="";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.handleField5 = this.handleField5.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.state = {
            helpCentreFaq: [],
            expandedField3: false,
            expandedField4: false,
            expandedField5: false,
            expandedField6: false,
            isStartDestOpen: false,
            direction:null,
            showstartlocation: false,
            showendLocation: false,
            fieldClicked:false,
            additionalField:null,
            startlocationerror: false,
            changeBorder:false,
            changeBorder2:false,
            endlocationerror: false,
            startorigin:null,
            endorigin:null,
            autoCompleteStart: null,
            autoCompleteEnd: null,
            cartypeerror: false,
            isToastOpen: false,
            startlocation: "",
            destinationlocation: "",
            home: [{ name: 'Place 1', description: 'Description of Place 1' },
            ],
            work: [{ name: 'Place 1', description: 'Description of Place 1' },
            ],
            location: [{ name: 'Place 1', description: 'Description of Place 1' },
            { name: 'Place 2', description: 'Description of Place 2' },
            ],
            isCarPopUpOpen: false,
            selectedCar: {
                name: "",
                image: "",
                distane: "",
                price: "",
                id: ""
            },
            LocationErrrorPopUp: false,
            InternetErrorPopUp: false,
            rideId:null,
            ordersummarydetails:[],
            fromTripHistoryScreen:false,
            carData:[],
            selectedCar2:{},
            staticLocations:[
                { id: 1, name: "Home", address: "123 Main St, City", type: "home" },
                { id: 2, name: "Work", address: "456 Office Rd, City", type: "work" },
              ],
            dynamicLocations:[],
            anchorEl:null,
            selectedId:null,
            additionalData:null,
            favLoc:false,
            deletePopUp:false,
            isToastOpen1:false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getCarpopUpData()
        this.getVehicalData()
        if (this.props.rideId) {
            this.setState({fromTripHistoryScreen:true})
            this.handleRideSummary({data:this.props.rideId});
          }
          
            this.fetchfavLocationData();
        
         
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const rideId = message.getData(
              getName(MessageEnum.SignUpDriverNextData) 
            );
            this.setState({fromTripHistoryScreen:rideId.fromTripHistoryScreen})
            this.handleRideSummary(rideId);
          }
          if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
              if (apiRequestCallId === this.orderSummaryApiCallId) {
                  if (responseJson && responseJson.data) {
                      this.processResponseData(responseJson);
                  }

              }
              if (apiRequestCallId === this.getCarPopUpApiCallId) {
                if (responseJson && responseJson.data) {
                    this.setState({carData:responseJson.data})
                    console.log("kavita",responseJson.data)
                }

            }
            if (apiRequestCallId === this.favPlacesApiCallId) {
                if (responseJson && responseJson.data) {
                    this.setState({dynamicLocations:responseJson.data});
                }
                if (responseJson && responseJson.errors==="No favourite locations present") {
                    this.setState({dynamicLocations:[]});
                }

            }
            if (apiRequestCallId === this.deletefavPlacesApiCallId) {
                if (responseJson && responseJson.message==="Location deleted successfully") {
                   this.setState({deletePopUp:false,anchorEl:null,isToastOpen1:true})
                   this.fetchfavLocationData()
                }

            }
        this.receiveVehicalDetials(message)


        }  
        // Customizable Area End
    }

    // Customizable Area Start
    getPopoverHorizontalPosition = (window:any) => {
        return window.innerWidth < 600 ? "center" : "right";
    };
    getPopoverPosition = (window:any) => {
        return window.innerWidth < 600 ? "center" : "left";
    };
    getPopoverMargin = (window:any) => {
        return window.innerWidth < 600 ? "0px" : "50px";
    };
    handleToastClose = () => {
        this.setState({ isToastOpen1: false })
    }
    fetchfavLocationData=()=>{
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.favPlacesApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.favPlacesapi}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);      
    }
    handleOpenPopover = (event:any, id:any) => {
        this.setState({ anchorEl: event.currentTarget, selectedId: id });
      };
    handleDeletePopUP=()=>{
        this.setState({deletePopUp:true,anchorEl:null})
    }
    getVehicalData = async () => {
        let token = localStorage.getItem("token");

        const header = {
            'Content-Type': configJSON.validationApiContentType,
            token,
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.riderVehicalApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getVihicalaApiEndPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };
    receiveVehicalDetials = async(message: Message) => {
        
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.riderVehicalApiCallId &&
			this.riderVehicalApiCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
            
			if(responseJson){
                const formattedData = responseJson.data.map((item: Vehicle) => ({
                    ...item,  
                    isSelected: false,
                    tickSign: tickSign.default
                  }));
                  this.setState({additionalField:formattedData})
                
		}

    }
	}
    handlePlaceSelect = (autocomplete: AutocompleteType | null) => {
        if (autocomplete) {
          const place = autocomplete.getPlace();
          this.setPlaceData(place)
          
        }
      };
      setPlaceData=(place:any)=>{
        this.setState({startlocation:place.formatted_address || ""})
          if (place.geometry && place.geometry.location) {
            this.setState({ startorigin: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              } });
          }
      }
     getDirections = () => {
        if (!this.state.startorigin || !this.state.endorigin) {
          return;
        }
        const origin = this.state.startorigin
        const destination = this.state.endorigin
        if (window.google && window.google.maps) {
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin,
            destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === "OK") {
              this.setState({direction:result});
            } else {
              console.error(`Error fetching directions: ${status}`);
            }
          }
        );
    }
      };
    
    
      handleDestinationSelect = (autocomplete: AutocompleteType | null) => {
        if (autocomplete) {
          const place = autocomplete.getPlace();
          this.setState({destinationlocation:place.formatted_address || ""})
            this.handlePlace(place)
        }
        this.getDirections()
      };
      handlePlace=(place:any)=>{
        if (place.geometry && place.geometry.location) {
            this.setState({ endorigin: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              } });
          }
      }

    handleFieldClick = (id:number,data:Vehicle) => {
        this.setState({additionalData:data,fieldClicked:!this.state.fieldClicked,cartypeerror:false})
        this.setState((prevState) => {
          const updatedFields = prevState.additionalField && prevState.additionalField.map((data) => {
            if (data.id === id.toString()) {
                data.isSelected = true;
              } else {
                data.isSelected = false;
              }
              return data;
          });
          
          return { additionalField: updatedFields };
        });
      };
    handleClosePopover = () => {
        this.setState({ anchorEl: null, selectedId: null },);
    };
    onCloseDeletePopup = () => {
        this.setState({ deletePopUp: false });
    }
    deleteLocation = () => {
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deletefavPlacesApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteFavPlacesapi}${this.state.selectedId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodTypeDelete
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleFavPopup = () => {
        this.setState({ favLoc: true })
    }
    getCarpopUpData=()=>{
        const token = localStorage.getItem("token");
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getCarPopUpApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.CarAPiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    hasCarData=()=>{
        return this.state.carData && this.state.carData.length > 0;
    }
    getCarImageUrl = (car:any) => {
        return car.attributes?.car_image?.url || 'default-image-url.jpg';
      };
    processResponseData(responseJson:any) {
        this.setState({
            ordersummarydetails: responseJson.data.attributes,
            startlocation: responseJson.data.attributes.start,
            destinationlocation: responseJson.data.attributes.destination
        });
    
        const carName = responseJson.data.attributes.car_type;
        const matchingCar = carData.find((car) => car.name === carName);
    
        if (matchingCar) {
            this.setState({
                selectedCar: {
                    ...matchingCar 
                }
            });
        }
    }
    onClose=()=>{
    this.setState({favLoc:false})
    }
    handleRideSummary(rideId:any) {
        const token = localStorage.getItem("token");
        this.setState({rideId:rideId.data})
        this.orderSummaryApi(token, rideId.data);
    }
    orderSummaryApi = (token: any,rideid:number) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.orderSummaryApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.ordersummaryapi}${rideid}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    handleField3 = () => {
        this.setState({ expandedField3: !this.state.expandedField3 });
    }

    handleField4 = () => {
        this.setState({ expandedField4: !this.state.expandedField4 });
    }
    changeBorderColor=()=>{
        this.setState({changeBorder:!this.state.changeBorder})
      }
      changeBorderColor2=()=>{
        this.setState({changeBorder2:!this.state.changeBorder2})
      }
      userAddress=(event: React.ChangeEvent<HTMLInputElement>)=>{
        
        this.setState({startlocation:event.target.value,startlocationerror:false})
    }
    onFieldClicked=()=>{
        this.setState({fieldClicked:!this.state.fieldClicked})
    }
    userEndAddress=(event: React.ChangeEvent<HTMLInputElement>)=>{
        
        this.setState({destinationlocation:event.target.value,endlocationerror:false})
    }
    handleSelect = (place:Place) => {
        this.setState({startlocation:place.formatted_address})
      };
   
      clearAddressField = ()=>{
        this.setState({startlocation:""})
    }
    clearAddressField2 = ()=>{
        this.setState({destinationlocation:""})
    }

    moveTonextStep = () => {
        const { startlocation, destinationlocation, selectedCar,fromTripHistoryScreen,rideId } = this.state;
        const startlocationerror = !startlocation;
        const endlocationerror = !destinationlocation;
        const cartypeerror = Object.values(selectedCar).some((value) => !value);
        this.setState(
            {
                startlocationerror,
                endlocationerror,
                cartypeerror,
            },
            () => {
                if (!startlocationerror && !endlocationerror && !cartypeerror) {
                    this.props.setactive()
                    const data = {
                        startlocation,
                        destinationlocation,
                        selectedCar,
                        fromTripHistoryScreen,
                        rideId
                    }
                    this.props.planride(data)
                }
            }
        );
    }
    getCarPrice = (car:any) => {
        return car.attributes?.price ? car.attributes.price : "100";
      };
    handleClose = () => {
        this.setState({ isToastOpen: false })
    }

    handleField5() {
        this.setState({ expandedField5: !this.state.expandedField5 });
    }
    handleField6() {
        this.setState({ expandedField6: !this.state.expandedField6 });
    }
    onStartDestClose = () => {
        this.setState({ isStartDestOpen: false });
    }

    onContinue = () => {
        if (this.state.startlocation && this.state.destinationlocation) {
            this.setState({ isStartDestOpen: false, showstartlocation: true, showendLocation: true, endlocationerror: false, startlocationerror: false });
        } else {
            this.setState({ isToastOpen: true })
        }
    }
    openStartDestPopup = () => {
        this.setState({ isStartDestOpen: true});

    }
    onCarPopupClose = () => {
        this.setState({ isCarPopUpOpen: false })
    }
    onCarPopupOpen = () => {
        this.setState({ isCarPopUpOpen: true })
    }
    handleCarSelect = (car: any) => {
        const selectedCar={name :car.attributes.car_type};

        this.setState({ selectedCar: selectedCar, cartypeerror: false,selectedCar2:car })
        this.onCarPopupClose()
    };
   
    getBackgroundColor = (car: any, selectedCar: any) => {
        return selectedCar === car ? '#F1F5F9' : 'transparent';
    };
    getBorder = (car: any, selectedCar: any) => {
        return selectedCar === car ? '2.48px solid #E2E8F0' : '0px';
    }
    getBorderRadius = (car: any, selectedCar: any) => {
        return selectedCar === car ? '10px' : '0px';
    }

    openLocationErrorPopUp = () => {
        this.setState({ LocationErrrorPopUp: true })
    }
    openInternetErrorPopUp = () => {
        this.setState({ InternetErrorPopUp: true })
    }
    closeLocationErrorPopUp = () => {
        this.setState({ LocationErrrorPopUp: false })
    }
    closeInternetErrorPopUp = () => {
        this.setState({ InternetErrorPopUp: false })
    }
    handleStartLocationChange = (value:any) => {
        const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        this.setState({ 
            startlocation: sanitizedValue, 
            startlocationerror: sanitizedValue.trim() === '' 
        });
    };
    handleDestinationLocationChange = (value:any) => {
        const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        this.setState({ 
            destinationlocation: sanitizedValue, 
            endlocationerror: sanitizedValue.trim() === '' 
        });
    };
    // Customizable Area End
}