// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const plus = require("../assets/plus.png");
export const TypeNull = require("../assets/TypeNull.png");
export const deleteImg = require("../assets/delete.png");
export const edit = require("../assets/edit.png");
export const danger = require("../assets/danger.png");
export const moreIcon = require("../assets/icon.png");
// Customizable Area End