// Customizable Area Start
import React from "react";
import {
  styled,
  Typography,
  Box,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  IconButton,
  InputAdornment,
  LinearProgress,
  FormLabel,
  Modal,
  CircularProgress
} from '@mui/material';
import SignUpRiderController, { AutocompleteType, configJSON } from "./SignUpRiderController.web";
import { Logo, checkbox, checked, error, location, retry } from "./assets";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete } from "@react-google-maps/api";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import PhoneInput from "react-phone-input-2";
import ClearIcon from '@mui/icons-material/Clear';
import Select from "react-select";
import countryList from "react-select-country-list";
import EditIcon from '@mui/icons-material/Edit';
import "react-phone-input-2/lib/style.css";
import SearchIcon from '@mui/icons-material/Search';

// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class SignUpRider extends SignUpRiderController {
  // Customizable Area Start
  stepOne = () => {
    return (
      <Box data-testId={"continueStepOneId"} component={"form"} onSubmit={this.handleSubmitRiderAccount} sx={{width:"100%",maxWidth:"440px",fontFamily:"Inter"}}>
        <RadioGroup
          row
          value={this.state.role}
          onChange={this.handleRoleChange}
          data-testId={"radioButtonId"}
        >
          <FormControlLabel
            value="rider"
            control={
              <RadioStepOne />
            }
            label="Rider"
          />
          <FormControlLabel
            value="driver"
            control={
              <RadioStepOne />
            }
            label="Driver"
          />
        </RadioGroup>
        {
          !this.state.agreeToTerms && 
        <TermConditionBox>
          You have to agree with Privacy Policy and Terms and Conditions to Sign up
        </TermConditionBox>
        }
        <InputContainer>
        <InputWrapper1>
          <CustomLabel1>{configJSON.fname}</CustomLabel1>
          <TextFieldCustom
            variant="outlined"
            placeholder="Type here your First Name..."
            fullWidth
            required
            name={"firstName"}
            value={this.state.firstName}
            onChange={this.handleChangeForFLName}
            data-testId={"firstNameId"}
            inputProps={{
              minLength: 3,
              maxLength: 50,
            }}
          />
        </InputWrapper1>

        <InputWrapper1>
          <CustomLabel1>{configJSON.lname}</CustomLabel1>
          <TextFieldCustom
            variant="outlined"
            placeholder="Type here your Last Name..."
            fullWidth
            required
            name={"lastName"}
            value={this.state.lastName}
            onChange={this.handleChangeForFLName}
            inputProps={{
              minLength: 3,
              maxLength: 50,
            }}
          />
        </InputWrapper1>

        <InputWrapper1>
          <CustomLabel1>{configJSON.email}</CustomLabel1>
          <TextFieldCustom
            data-testId={"emailId"}
            variant="outlined"
            fullWidth
            required
            placeholder="Type here your email address..."
            type="email"
            name={"email"}
            value={this.state.email}
            onChange={this.handleEmailChange}
            error={this.state.emailError}
          />

          {this.state.emailError && (
            <InvalidEmail>
              {configJSON.invalidEmail}
            </InvalidEmail>
          )}

        </InputWrapper1>

        <InputWrapper1>
          <CustomLabel1>Password</CustomLabel1>
          <TextFieldCustom
            data-testId={"passwordId"}
            type={this.state.showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            required
            placeholder="Type here your password..."
            name={"password"}
            value={this.state.password}
            onChange={this.handlePasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? (
                      <Visibility /> 
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper1>
        {this.state.password && !(
    this.state.passwordCriteria.hasUpperCase &&
    this.state.passwordCriteria.hasLowerCase &&
    this.state.passwordCriteria.hasNumber &&
    this.state.passwordCriteria.hasSpecialChar &&
    this.state.passwordCriteria.isValidLength
) && (
    <ValidationDiv>
        <ValidationText>
          {this.state.passwordCriteria.hasUpperCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasUpperCase}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.hasLowerCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasLowerCase}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.hasNumber ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasNumber}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.hasSpecialChar ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasSpecialChar}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.isValidLength ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.minimumChar}
        </ValidationText>
    </ValidationDiv>
)}
  </InputContainer>
    <CheckBoxContainer  data-testId={"checkBoxId"} onClick={this.handleCheckboxChange}>
      {
        this.getCheckBox()
      }
       <TypographyIagress
              variant="body2"
              color={!this.state.agreeToTerms ? '#DC2626' : 'inherit'}
              onClick={() => this.navigateTermscondition("TermsConditions")}
              data-test-id="signupto-terms-condition"
            >
              <span>I agree with <span style={{textDecoration:"underline"}}>Privacy Policy and Terms and Conditions</span></span>
            </TypographyIagress>
      </CheckBoxContainer>

        <ButtonOne
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          disabled={!this.state.agreeToTerms}
          type="submit"
        >
          {this.state.btnLoader ? <CircularProgress /> : configJSON.continue}
        </ButtonOne>

        <BottomText>
          <TypographyBackTo>
            {configJSON.alreadyHavAccount}
          </TypographyBackTo>
          <ButtonBackto
            data-testId={"loginBtnId"} onClick={this.handleLogin}>{configJSON.login}</ButtonBackto>
        </BottomText>
      </Box>
    )
  }
  getCheckBox = ()=>{
    if(this.state.agreeToTerms){
      return (
        <CustomImg src={checked.default} alt="checkbox" />
      )
    }
    else{
      return (
        <CustomImg src={checkbox.default} alt="checkbox" />
      )
    }
  }

  stepTwo = () => {
    const StyledTypography = styled(Typography)<StyledTypographyProps>(({ isSelected }) => ({
      padding: '12px',
      border: '1px solid #C0C0C0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color:this.getColor(isSelected), // Black if selected, gray otherwise
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    }));
    return (
      <Box component={"form"} data-testId={"stepTwoContinueId"} onSubmit={this.handleSubmitTwo} >
        {this.state.riderMobNoError ? (
           <CheckboxValidationContainer>Mobile number is already taken</CheckboxValidationContainer>
        ) : (
          null
        )}
        <InputWrapper2>
          <CustomLabel>{configJSON.moNumber}</CustomLabel>
          <CustomPhoneNumber style={{border:this.state.mobileNumberError2 && "1px solid red"}}>
            <CustomPhoneInput
              country={"gb"} 
              onChange={this.countryCodeData}
              placeholder="Type your phone number"
              inputClass="!w-full !h-12 !text-lg !rounded-lg !border-gray-300 !pl-14"
              containerClass="!w-full"
              buttonClass="!bg-transparent !border-none"
              dropdownClass="!rounded-lg !border-gray-300 !shadow-md"
            />
            {
              this.state.mobileNumber &&
              <CountryCode>{`(+${this.state.countryCode})`}</CountryCode>

            }
            <CustomeMobileNumber onChange={this.handleChangeMobileNo} placeholder="Type here your mobile number..."  value={this.formatNumber(this.state.mobileNumber)} type="tel" id="mobile" name={"mobileNumber"} max="10" />
          </CustomPhoneNumber>
          {this.state.mobileNumberError2 && (
            <InvalidPhone data-test-id="mobileNo2">
             Mobile number is required
            </InvalidPhone>
          )}
           {this.state.mobileNumberError && (
            <InvalidPhone data-test-id="mobileNo">
              Mobile number must be between 10 and 12 digits.
            </InvalidPhone>
          )}
        </InputWrapper2>

        <FormControl fullWidth margin="normal">
          <MyFormLabel>{configJSON.requirement}</MyFormLabel>
            <TextFieldCustom
            variant="outlined"
            fullWidth
            placeholder="Select car type..."
            type="tel"
            name="AdditionalField"
            onClick={this.onFieldClicked}
            value={this.state.additionalData}
            InputProps={{
              endAdornment: ( 
                <InputAdornment position="end">
                  {this.state.fieldClicked ?  <ArrowDropUpIcon />  : <ArrowDropDownIcon />}
                </InputAdornment>
              )
            }}
          />
         { this.state.fieldClicked &&
          <AdditionaWrapper>
              {this.state.additionalField &&
                this.state.additionalField.map((data)=>(
                  <div onClick={()=>this.handleFieldClick(data.attributes.id, data.attributes.name)}  key={data.id} style={{cursor:"pointer",padding:"0px 16px 0px 16px",zIndex:1,display:"flex",alignItems:"center",justifyContent:"space-between", width:"100%",height:"72px",backgroundColor:data.isSelected ?"#ECFDF5":"#FFFFFF",color:"#0F172A",fontSize:"16px",fontWeight:400}} >
                      <div style={{display:"flex",alignItems:"center"}}>
                      <div style={{height:"48px",width:"80px",alignContent:"center"}}><img src={data.attributes?.image} alt="logo" /></div>
                      <div style={{height:"100%",display:"flex",alignItems:"center"}}>{ data.attributes.name}</div>
                      </div>
                      {data.isSelected && 
                      <div>
                         <img src={data.tickSign} alt="ticksign" />
                        </div>
                      }
                    </div>
                ))
              
              }
          </AdditionaWrapper>}
        </FormControl>

        <InputWrapper2>
          <CustomLabel>{configJSON.anythingElse}</CustomLabel>
          <TextFieldCustom
            variant="outlined"
            name={"anythingElse"}
            value={this.state.anythingElse}
            onChange={this.handleChangeInput}
            fullWidth
            placeholder={configJSON.iCanWalk}
          />
        </InputWrapper2>

        <ButtonOne
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 18 }}
          type="submit"
        >
          {this.state.btnLoader ? <CircularProgress /> : configJSON.continue}
        </ButtonOne>
      </Box>
    );
  };
  renderButtonContent = () => {
    return this.state.btnLoader ? <CircularProgress /> : configJSON.signUp;
  }
  onLoadStart = (autocomplete:AutocompleteType) => this.setState({autoCompleteStart:autocomplete});
  stepThree = () => {
    const options = countryList().getData();
    return (
      <>
       <CustomLabel3>Home address </CustomLabel3>
      <GoogleApiContainer style={{border:this.state.changeBorder && "2px solid #1976d2"}}>
        <div>
          <img style={{height:"24px",width:"24px"}} src={location.default} alt="location" />
        </div>

      <div style={{width:"100%"}}>
                    <Autocomplete
                        onLoad={this.onLoadStart}
                        onPlaceChanged={() => this.handleSelect(this.state.autoCompleteStart)}
                    >
                        <CustomInput type="text" placeholder="Type here your home address..." onChange={(event:any)=>this.setState({homeAddress:event.target.value})}  value={this.state.homeAddress} onClick={this.changeBorderColor}
                        onBlur={this.changeBorderColor} />
                    </Autocomplete>
                    </div>
      {
       this.state.homeAddress && 
        <ClearIcon onClick={this.clearAddressField}  />
      }
    </GoogleApiContainer>
        <InputWrapper3>
          <CustomLabel3>{configJSON.address1}</CustomLabel3>
          <TextFieldCustom3
            data-testId={"addressOne"}
            variant="outlined"
            name="addressOne"
            placeholder="Type here your Address line 1..."
            value={this.state.addressOne}
            onChange={this.handleChangeInput}
            fullWidth
            error={this.state.addressOneError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    {
                      this.state.addressOne &&
                      <EditIcon style={{color:"#94A3B8"}}/> 
                    }
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
            {this.state.addressOneError && (
            <ErrorMessage>
              Address Line 1 is required
            </ErrorMessage>
          )}
        </InputWrapper3>

        <InputWrapper3>
          <CustomLabel3>{configJSON.address2}</CustomLabel3>
          <TextFieldCustom3
            data-testId={"addressTwo"}
            variant="outlined"
            name={"addressTwo"}
            placeholder="Type here your Address line 2..."
            value={this.state.addressTwo}
            onChange={this.handleChangeInput}
            fullWidth
            error={this.state.addressTwoError}
          />
        </InputWrapper3>

        <InputWrapper3>
          <CustomLabel3>{configJSON.city}</CustomLabel3>
          <TextFieldCustom3
            data-testId={"cityId"}
            variant="outlined"
            name={"city"}
            value={this.state.city}
            placeholder="Type here your City..."
            onChange={this.handleChangeInput}
            fullWidth
            error={this.state.cityError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    {
                      this.state.city &&
                      <EditIcon style={{color:"#94A3B8"}}/> 
                    }
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
             {this.state.cityError && (
            <ErrorMessage>
             City is required
            </ErrorMessage>
          )}
        </InputWrapper3>
        <InputWrapper3>
          <CustomLabel3>{configJSON.zipCode}</CustomLabel3>
          <TextFieldCustom3
            data-testId={"zipId"}
            variant="outlined"
            placeholder="Type here your ZIP Code..."
            inputProps={{
              pattern: "[0-9]*", 
              onInput: this.takeonlyNumber
            }}
            name={"zipCode"}
            value={this.state.zipCode}
            onChange={this.handleChangeInput}
            fullWidth
            error={this.state.zipCodeError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    {
                      this.state.zipCode &&
                      <EditIcon style={{color:"#94A3B8"}}/> 
                    }
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
             {this.state.zipCodeError && (
            <ErrorMessage>
              ZIP code is required
            </ErrorMessage>
          )}
        </InputWrapper3>

        <InputWrapper3>
          <CustomLabel3>{configJSON.State}</CustomLabel3>
          <TextFieldCustom3
            data-testId={"state"}
            variant="outlined"
            onChange={this.handleChangeInput}
            name={"state"}
            value={this.state.state}
            placeholder="Type here your State/Province..."
            fullWidth
            error={this.state.countryError}
          />
             {this.state.stateAddError && (
            <ErrorMessage>
              State/Province is required
            </ErrorMessage>
          )}
        </InputWrapper3>

        <InputWrapper3>
          <CustomLabel3>{configJSON.country}</CustomLabel3>
            <CustomSelect
             data-testId={"countryId"}
        options={options}
        value={this.state.country}
        onChange={(event:any)=>this.handleCountryChange(event)}
        getOptionLabel={(event:any) =>{
          return  <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={`https://flagcdn.com/w40/${event.value.toLowerCase()}.png`}
            alt=""
            style={{ width: "20px", height: "15px", marginRight: "10px" }}
          />
          {event.label}
        </div> as unknown as string;
        }}
        styles={{
         
          control: (base:any) => ({
            ...base,
            padding: "5px",
            borderRadius: "6px",
            height:"56px",
            minHeight:"56px",
            border: "1px solid #ccc",
            boxShadow: "none",
            fontSize: "16px",
          }),
          dropdownIndicator: (base:any) => ({
            ...base,
            color: "#333",
          }),
        }}
        placeholder="Select Country"
      />
        </InputWrapper3>
        <ButtonOne
          data-testId={"signUpButtonId"}
          variant="contained"
          color="primary"
          fullWidth
          disabled={!this.state.addressOne && !this.state.city  && !this.state.zipCode && !this.state.state}
          onClick={this.handleFinalStep}
        >
          {this.renderButtonContent()}
        </ButtonOne>

      </>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <StyledBox>
        <LeftHalf>
          <FormContainer>
            <TypographyCreateYour>
              {configJSON.createYourEryde}
            </TypographyCreateYour>

            <ProgressBarContainer>
              <TypographyStepOne
                variant="body2"
                data-testId={"progress-step"}
              >
                Step {this.state.currentStep} of 3
              </TypographyStepOne>
              <CustomLinearProgress
                variant="determinate"
                value={this.state.currentStep === 1 ? 25 : this.state.currentStep === 2 ? 50 : 100}
                data-testId={"progress-bar"}
              />
            </ProgressBarContainer>

            {this.state.showApiError && (
              <CheckboxValidationContainer>
                <Typography data-testId={"terms-error"}>{this.state.apiErrorMessage}</Typography>
              </CheckboxValidationContainer>
            )}

            {this.state.currentStep === 1 && this.stepOne()}
            {this.state.currentStep === 2 && this.stepTwo()}
            {this.state.currentStep === 3 && this.stepThree()}

          </FormContainer>
        </LeftHalf>
        <RightHalf>
          <MainImgage src={Logo.default} alt="right-main" />

          <TextBox>
            <TypographyImgTextOne>{configJSON.yourSafe}</TypographyImgTextOne>
            <TypographyImgTextTwo>{configJSON.nonMedical}</TypographyImgTextTwo>
          </TextBox>
        </RightHalf>

        <Modal
  open={this.state.openSelectModal}
  onClose={this.handleCloseSelectModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  data-testId={'closeAdditionalRequirementModalID'}
>
  <BoxWheel boxShadow={24}>
    <SelectDriveType
      data-testId={'noneSelectId'}
      activeBTN={this.state.requirement === configJSON.none}
      onClick={() => this.handleSelectRequirement(configJSON.none)}
    >
      <CustomCheckboxWrapper>
      <CustomCheckbox>
        <input
          type="checkbox"
          checked={this.state.requirement === configJSON.none}
          readOnly
        />
        <span className="checkmark"></span>
      </CustomCheckbox>
      <SelectNameTypography data-testId={'noneTextID'}>
        {configJSON.none}
      </SelectNameTypography>
      </CustomCheckboxWrapper>
    </SelectDriveType>

    <SelectDriveType
    data-test-id="ButtonDriveType1"
      activeBTN={this.state.requirement === configJSON.wheelchair}
      onClick={() => this.handleSelectRequirement(configJSON.wheelchair)}
    >
      <CustomCheckboxWrapper>
      <CustomCheckbox>
        <input
          type="checkbox"
          checked={this.state.requirement === configJSON.wheelchair}
          readOnly
        />
        <span className="checkmark"></span>
      </CustomCheckbox>
      <SelectNameTypography>
        {configJSON.wheelchair}
      </SelectNameTypography>
      </CustomCheckboxWrapper>
    </SelectDriveType>

    <SelectDriveType
     data-test-id="ButtonDriveType2"
      activeBTN={this.state.requirement === configJSON.gurney}
      onClick={() => this.handleSelectRequirement(configJSON.gurney)}
    >
      <CustomCheckboxWrapper>
      <CustomCheckbox>
        <input
          type="checkbox"
          checked={this.state.requirement === configJSON.gurney}
          readOnly
        />
        <span className="checkmark"></span>
      </CustomCheckbox>
      <SelectNameTypography>
        {configJSON.gurney}
      </SelectNameTypography>
      </CustomCheckboxWrapper>
    </SelectDriveType>
  </BoxWheel>
</Modal>


        <Modal
          open={this.state.openErrorModal}
          onClose={this.handleErrorClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-testId={'closeOopsModalID'}
        >
          <ErrorBox>
            <BoxCenter>
              <ErrorImg src={error.default} alt="error" />
              <TypographyOops data-testId={"emptyErrorId"}>{configJSON.oops}</TypographyOops>
              <TypographyDouble>{configJSON.double}</TypographyDouble>
              <ButtonReturn onClick={this.handleErrorClose}>
                <ImgRetry src={retry.default} alt="retry" />
                <TypographyRetry>{configJSON.retry}</TypographyRetry>
              </ButtonReturn>
            </BoxCenter>
          </ErrorBox>
        </Modal>
      </StyledBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
interface StyledTypographyProps {
  isSelected: boolean;
}
const ErrorBox2 = styled("span")({
  display: "block",
  width: "100%",
  padding: "12px",
  backgroundColor: "#FEE2E2",
  color: "DC2626",
  border: "1px solid #f5c6cb",
  borderRadius: "8px",
  marginBottom: "16px",
  textAlign: "initial",
  boxSizing: "border-box",
  fontSize: "12px",
  fontWeight: 100,
});
const CustomCheckboxWrapper = styled('div')`
  display: flex;
  align-items: center; /* Align checkbox and text vertically */
  gap: 8px; /* Space between checkbox and text */
`;
const CustomCheckbox = styled('label')`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #ffffff;
    border: 2px solid #c0c0c0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input:checked + .checkmark {
    background-color: #87C122; 
    border: none;
  }

  input:checked + .checkmark::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 12px;
    border: solid #ffffff; /* White checkmark color */
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: 4px; /* Adjust vertical alignment */
  }
`;

const StyledBox = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
  '@media (max-width: 960px)': {
    flexDirection: "column",
    height: "auto",
  }
});

const GoogleApiContainer = styled('div')({
  width:"100%",
  maxWidth:"440px",
  position:"relative",
  height:"56px",
  border:"1px solid #CBD5E1",
  borderRadius:"8px",
  padding:"0px 8px",
  display:"flex",
  alignItems:"center",
  marginBottom:"15px"
});
const LeftHalf = styled("div")({
  width: "50%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#ffffff",
  "@media (max-width: 960px)": {
    width: "100%",
    height: "100%",
  }
});

const FormContainer = styled("div")({
  width: "440px",
  padding:"0px 2px",
  height: "100%",
  overflow: "scroll",
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: "none",
  "@media (max-width: 960px)": {
    width: "80%",
    marginTop: "20px"
  }
});

const RadioStepOne = styled(Radio)({
  '&.Mui-checked': {
    color: '#87C122',
  },
});


const CountryCode = styled("span")(({ theme }) => ({
  marginLeft:"10px",
  fontSize:"16px",
  fontWeight:400,
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px', 
    marginLeft: '45px', 
  },
}));

const CustomeMobileNumber = styled('input')({
  marginLeft:"10px",
  border:"none",
  fontSize:"16px",
  fontWeight:400
,  outline:"none",
  height:"100%",
 "&:focus":{
  border:"none",
  outline:"none"
 },
 '&::placeholder': {
  color: '#A6B0BF',  
},
});
const TypographyCreateYour = styled(Typography)({
  color: '#1D6FB8',
  fontWeight:400 ,
  width:"100%",
  maxWidth:"440px",
  fontFamily:"'Inter', sans-serif",
  fontSize: "30px",
  marginTop: "10px",
  textAlign: "center",
  "@media (max-width: 960px)": {
    fontSize: "20px",
  },
  "@media (max-width: 440px)": {
    fontSize: "23px",
  }
});

const CheckboxStepOne = styled(Checkbox)({
  borderRadius: '50px !important'
});

const InvalidEmail = styled(Typography)({
  color: "#DC2626",
  margin: "20px 0px 0px 0px"
})
const InvalidPhone = styled(Typography)({
  color: "#DC2626",
  margin: "5px 0px 0px 0px"
})

const ErrorMessage = styled('div')({
  color: "#DC2626",
  margin: "2px 0px 0px 0px",
  fontSize:"12px",
  fontWeight:400,
  fontFamily: "'Inter', sans-serif ",
})


const TypographyIagress = styled(Typography)({
  fontSize: '14px',
  fontWeight:400,
  width: '386px',
  fontFamily: "'Inter', sans-serif ",
  "@media (max-width: 960px)": {
    width: '100%',
  }
});

const TypographyStepOne = styled(Typography)({
  fontWeight: 400,
  fontSize: '18px',
  fontFamily: "'Inter', sans-serif ",
  color: '#1D6FB8',
  marginBottom: '8px',
  "@media (max-width: 460px)": {
    fontSize:"16px"
  }
});

const ProgressBarContainer = styled('div')({
  width:"100%",
  maxWidth: '440px',
  margin: '10px 0px 20px 0px',
});

const CustomLinearProgress = styled(LinearProgress)({
  height: '8px',
  borderRadius: '5px',
  backgroundColor: "#E0E0E0",
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#87C122',
  },
});

const ButtonOne = styled(Button)(({ theme }) => ({
  marginTop: '24px',
  marginBottom:"10px",
  height: '60px',
  fontFamily: "'Inter', sans-serif ",
  color: '#FFFFF',
  backgroundColor: '#1D6FB8',
  cursor: "pointer",
  textTransform: 'none',
  borderRadius: '10px',
  fontSize: "20px",
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const BottomText = styled("div")({
  textAlign: "center",
  fontWeight: 400,
  color: "#000000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "10px",
  cursor: 'pointer',

});

const TypographyBackTo = styled(Typography)({
  color: "#000000",
});

const ButtonBackto = styled(Typography)({
  color: '#1D6FB8',
  marginLeft: "5px",
  textDecorationLine: 'underline'
});

const InputWrapper = styled(Box)({
  width: '100%',
  margin: '20px 0px',
});
const InputWrapper1 = styled(Box)({
  width: '100%',
  margin: '12px 0px',
});
const InputWrapper3 = styled(Box)({
  width: '100%',
  maxWidth:"440px",
  margin: '0px 0px 20px 0px',
});
const CustomSelect = styled(Select)({
  height: "56px",
  minHeight: "56px",
  "& .css-9ixc12-control": {
    height: "56px", 
    minHeight: "56px",
    borderRadius: "8px",
  },
  "& .css-1wa3eu0-placeholder": {
    lineHeight: "56px", 
  },
  "& .css-1g6gooi": {
    height: "100%", 
    display: "flex",
    alignItems: "center", 
  },
  "& .css-1u9des2-indicatorSeparator": {
    backgroundColor: "white",
  },
});


const AdditionaWrapper = styled('div')({
 zIndex:40,
 marginTop:"85px",
 position:"absolute",
 width:"100%",
 maxWidth:"440px",
 boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
 border:"1px solid #F5F5F5",height:"216px",
 backgroundColor:"#FFFFFF",borderRadius:"8px"
});

const InputContainer = styled(Box)({
  display:"flex",
  flexDirection:"column",
});

const CheckBoxContainer = styled(Box)({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  gap:10,
  margin:"20px 0px"
});
const InputWrapper2 = styled(Box)({
  width: '100%',
});
const CustomLabel = styled(Typography)({
  backgroundColor: 'white',
  fontSize: '14px',
  color: '#334155',
  fontFamily:"'Inter', sans-serif",
  fontWeight: 700,
  marginBottom: '5px',
  marginTop:"20px"

});
const CustomLabel1 = styled(Typography)({
  backgroundColor: 'white',
  fontSize: '14px',
  color: '#334155',
  fontFamily:"'Inter', sans-serif",
  fontWeight: 700,
  margin: '5px 0px',

});
const CustomLabel3 = styled(Typography)({
  backgroundColor: 'white',
  fontSize: '14px',
  color: '#334155',
  fontFamily:"'Inter', sans-serif",
  fontWeight: 700,
  margin: '5px 0px',

});

const TextFieldCustom = styled(TextField)({
  width: '100%',
  fontFamily:"'Inter', sans-serif",
  fontWeight:700,
  height: "40px",
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
  },
});

const TextFieldCustom3 = styled(TextField)({
  width: '100%',
  fontFamily:"'Inter', sans-serif",
  fontSize:"16px",
  color:"#0F172A",
  fontWeight:600,
  height: "56px",
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
  },
});

const ValidationText = styled(Typography)({
  fontSize: "14px",
  color: "#000000",
  marginTop: "10px",
  display: "flex",
  alignItems: "center",
});


const ValidationDiv = styled(Box)({
  margin:"20px 0px 0px 0px"
});

const Circle = styled("span")({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  fontSize: "12px",
  color: "white",
  fontWeight: "bold",
  marginRight: "8px",
});

const CircleGreen = styled(Circle)({
  backgroundColor: "#34D399",
  textAlign: "center",
  fontSize: "15px"
});

const CircleRed = styled(Circle)({
  backgroundColor: "#DC2626",
  textAlign: "center",
  fontSize: "15px"
});

const CheckboxValidationContainer = styled(Box)({
  marginBottom: "24px",
  padding: "10px",
  border: "1px solid #DC2626",
  borderRadius: "4px",
  backgroundColor: "#FEE2E2",
  color: "#DC2626",
  fontSize: "14px"
});

const MyFormLabel = styled(FormLabel)({
  color: '#334155',
  fontWeight: 700,
  fontFamily:"'Inter', sans-serif",
  letterSpacing: '0.5px',
  fontSize: '14px',
  marginBottom: '5px',
});

const CustomPhoneNumber = styled('div')({
height:"56px",
width:"100%",
maxWidth:"440px",
border:"1px solid #CBD5E1",
borderRadius:"8px",
display:"flex",
alignItems:"center",
padding:"10px 8px"
});

const CustomPhoneInput = styled(PhoneInput)({
  "&.react-tel-input .flag-dropdown": {
    border:"none",
    backgroundColor: "white"
  },
  "&.react-tel-input":{
    width:"40px" 
  },
  "&.react-tel-input .form-control":{
    display:"none"
  },
  "&.react-tel-input .selected-flag .arrow":{
    left:"24px",
    height:"4px",
    width:"7px"
  }
  });
const SelectDialog = styled(Box)({
  background: '#FFFFFF',
  color: '#A0A0A0',
  '& .MuiTypography-root': {
    borderRadius: '10px',
  },
});



const IconButtonArrow = styled(IconButton)({
  marginLeft: '110px',
});

const ButtonBackButton = styled(Button)(({ theme }) => ({
  margin: '15px 0px',
  height: '60px',
  color: '#1D6FB8',
  border: '1px solid #1D6FB8',
  borderRadius: '10px',
  width: '100%',
  backgroundColor: '#FFFFFF',
  textTransform: 'none',
  fontSize: '20px',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: '#FFFFFF',
  },
}));

const FormControlLabelCustom = styled(FormControlLabel)({
  marginTop: '10px',
  fontFamily: "'Inter', sans-serif "
});

const BoxWheel = styled(Box)({
  boxSizing: 'border-box',
  overflow: 'hidden',
  padding: '0px',
  margin: '0px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '360px',
  backgroundColor: '#ffffff',
  borderRadius: '20.89px',
  "@media (max-width: 960px)": {
    width: "90%"
  }
});

const SelectDriveType = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'activeBTN',
})<{ activeBTN: boolean; }>(({ activeBTN }) => ({
  display: 'flex',
  padding: '20px',
  boxSizing: 'border-box',
  margin: '0px',
  backgroundColor: activeBTN ? '#ECFDF5' : '#ffffff',
}));

const SelectBoxModal = styled(Box)({
  border: '2px solid #64748B',
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  marginRight: '18px',
  marginLeft: '13px',
});
const CustomInput = styled('input')(({ theme }) => ({
  fontFamily: "'Inter', sans-serif ",
  width: "95%",
  maxWidth:"360px",
  fontSize:"16px",
  fontWeight:400,
  height: "45px",
  wordBreak:"break-word",
  whiteSpace:"normal",
  border:"none",
  paddingLeft:"10px",
  "&:focus": {
    outline: "none",  
    border: "none",  
  },

}));

const CustomImg = styled('img')(({ theme }) => ({
  height:"100%",
  width:"100%",
  maxHeight:"20px",
  maxWidth:"20px",
  [theme.breakpoints.down("sm")]: {
    height: "15px",
    width: "15px",
  },
}));

const SelectNameTypography = styled(Typography)({
  fontSize: '24px',
  letterSpacing: '1px',
  color: "#3C3E49",
});

const ErrorBox = styled(Box)({
  width: '445px',
  height: '450px',
  overflow: 'hidden',
  backgroundColor: '#FEE2E2',
  padding: '25px',
  borderRadius: '24.89px',
  borderLeftStyle: 'solid',
  borderLeftColor: '#DC2626',
  borderLeftWidth: '5px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  margin: '0px',
  "@media (max-width: 960px)": {
    width: "90%",
    height: "80%",
    padding: '10px',
  }
});

const BoxCenter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const ErrorImg = styled('img')({
  height: '195.93px',
  "@media (max-width: 960px)": {
    width: "90%",
    height: '180px',
  }
})

const TypographyOops = styled(Typography)({
  color: '#DC2626',
  fontSize: '20px',
  marginTop: '15px',
  "@media (max-width: 960px)": {
    fontSize: '16px',
    marginTop: '15px',
  }
})

const TypographyDouble = styled(Typography)({
  color: '#94A3B8',
  marginTop: '15px',
  fontSize: '20px',
  textAlign: 'center',
  "@media (max-width: 960px)": {
    fontSize: '16px',
    marginTop: '15px',
  }
})

const ButtonReturn = styled(Box)({
  background: '#DC2626',
  ':hover': {
    backgroundColor: '#DC2626',
    cursor:"pointer"
  },
  color: '#FFFFFF',
  width: '55%',
  height: '60px',
  borderRadius: '16px',
  marginTop: '15px',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 960px)": {
    width: '80%',
    height: '50px',
    marginTop: '20px',
  }
})

const TypographyRetry = styled(Typography)({
  fontSize: '25px',
  margin: '5px 5px 0px 0px',
  "@media (max-width: 960px)": {
    fontSize: '15px',
    marginRight: '12px'
  }
})

const ImgRetry = styled('img')({
  width: '30px',
  height: '30px',
  margin: '0px 7px',
  "@media (max-width: 960px)": {
    width: '25px',
    height: '25px',
  }
})

const RightHalf = styled(Box)({
  width: "50%",
  height: "100%",
  textAlign: 'center',
  backgroundColor: "#ECFDF5",
  "@media (max-width: 960px)": {
    width: "100%",
    height: "100%",
  }
})
const MainImgage = styled('img')({
  width: '100%',
  height: '85%',
  "@media (max-width: 960px)": {
    width: '100%',
  }
});

const TextBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

const TypographyImgTextOne = styled(Typography)({
  color: '#1D6FB8',
  marginTop: '14px',
  fontWeight: 400,
  fontSize: '20px',
  "@media (max-width: 960px)": {
    fontSize: '15px',
  }
});

const TermConditionBox = styled(Button)(({ theme }) => ({
  width:"100%",
  maxWidth: "440px",
  height: "68px",
  marginTop:"10px",
  backgroundColor: "#FEE2E2",
  color: "#DC2626",
  display: "flex",
  textAlign:"left",
  textTransform:"none",
  gap:"8px",
  padding: "12px 16px 12px 16px",
  borderLeft: "4px solid #DC2626",
  borderRadius: 4,
  fontSize: "14px",
  fontWeight:400,
  '&:hover':{
    backgroundColor: "#FEE2E2",
  }

}));

const TypographyImgTextTwo = styled(Typography)({
  color: '#1D6FB8',
  marginTop: '14px',
  fontWeight: 400,
  fontSize: '20px',
  "@media (max-width: 960px)": {
    fontSize: '15px',
  }
})
// Customizable Area End
